import firebase from 'firebase';
import {
  Portal,
  Modal,
  ModalHeader,
  ModalBody,
  ModalOverlay,
  ModalContent,
  VStack,
  ModalFooter,
  Button,
  HStack,
  useToast,
} from '@chakra-ui/react';
import * as React from 'react';
import * as Yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { Venue } from '../../types/Venue';
import states from 'states-us/dist';
import { timeZones } from '../../lib/timeZones';
import { ProfileImagesEdit } from '../ProfileImagesEdit';
import { getFormValuesFromVenue } from '../../lib/getFormValues';
import { SimpleInput } from '../SimpleInput';
import { SimpleTextarea } from '../SimpleTextarea';
import { SimpleSelect } from '../SimpleSelect';
import { SimpleCheckbox } from '../SimpleCheckbox';
import { useFanflexUser } from '../../hooks/useFanflexUser';

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  urls: Yup.string(),
  publiclyHidden: Yup.boolean().required(),
  description: Yup.string().required(),
  location: Yup.object().shape({
    city: Yup.string().required(),
    state: Yup.string().required(),
    streetAddress: Yup.string().required(),
    timeZone: Yup.string().required(),
  }),
});

interface IEditVenueModal {
  header: string;
  venueRef: firebase.firestore.DocumentReference;
  storageRef: firebase.storage.Reference;
  venue?: Venue;
  onClose: () => void;
}

export const EditVenueModal: React.FC<IEditVenueModal> = ({
  header,
  onClose,
  storageRef,
  venue,
  venueRef,
}) => {
  const { isAdmin } = useFanflexUser();
  const toast = useToast();
  const initialValues = getFormValuesFromVenue(venue);

  const onSubmit = async (values: any) => {
    try {
      await venueRef.set(values, { merge: true });
      toast({
        title: 'Successfully Updated!',
        status: 'success',
      });
      onClose();
    } catch (reason: any) {
      toast({
        title: 'Uhoh. Something went wrong',
        description: reason.message,
        status: 'error',
      });
      console.error(reason);
    }
  };

  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    validationSchema,
    onSubmit,
  });

  return (
    <Portal>
      <FormikProvider value={formik}>
        <Modal isOpen onClose={onClose} size="xl" closeOnOverlayClick={false}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{header}</ModalHeader>
            <ModalBody>
              <VStack spacing={6} alignItems="flex-start">
                <VStack spacing={6} width="100%">
                  <SimpleInput name="name" label="Name" />
                  {isAdmin && (
                    <SimpleCheckbox
                      name="publiclyHidden"
                      label="Hide from Search"
                    />
                  )}
                </VStack>

                <ProfileImagesEdit
                  documentRef={venueRef}
                  storageRef={storageRef}
                />

                <SimpleTextarea name="description" label="Description" />

                <SimpleTextarea
                  name="urls"
                  label="Links"
                  helper="Add all your social and web links separated by a new line."
                />

                <SimpleInput name="location.streetAddress" label="Street" />
                <HStack width="100%" spacing="4" alignItems="flex-start">
                  <SimpleInput name="location.city" label="City" />
                  <SimpleSelect name="location.state" label="State">
                    {states.map(({ abbreviation, name }) => (
                      <option key={abbreviation} value={abbreviation}>
                        {name}
                      </option>
                    ))}
                  </SimpleSelect>
                </HStack>
                <SimpleSelect name="location.timeZone" label="TimeZone">
                  {timeZones.map(({ label, tzCode }) => (
                    <option key={tzCode} value={tzCode}>
                      {label}
                    </option>
                  ))}
                </SimpleSelect>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button onClick={onClose} isLoading={formik.isSubmitting}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={formik.submitForm}
                  disabled={!formik.isValid}
                  isLoading={formik.isSubmitting}
                >
                  Save
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </FormikProvider>
    </Portal>
  );
};
