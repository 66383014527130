import firebase from 'firebase';
import {
  Text,
  Portal,
  Modal,
  ModalHeader,
  ModalBody,
  ModalOverlay,
  ModalContent,
  Stack,
  VStack,
  HStack,
  Avatar,
  Heading,
  Divider,
  ModalFooter,
  Button,
  useToast,
} from '@chakra-ui/react';
import * as React from 'react';
import * as Yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import states from 'states-us/dist';

import { Artist } from '../../types/Artist';
import { SimpleInput } from '../SimpleInput';
import { SimpleSelect } from '../SimpleSelect';
import { SimpleTextarea } from '../SimpleTextarea';
import { getFormValuesFromArtist, IArtistForm } from '../../lib/getFormValues';
import { ProfileImagesEdit } from '../ProfileImagesEdit';
import { GenreSelect } from '../GenreSelect';
import { SimpleCheckbox } from '../SimpleCheckbox';
import { useFanflexUser } from '../../hooks/useFanflexUser';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  publiclyHidden: Yup.bool(),
  slug: Yup.string(),
  genres: Yup.array().of(Yup.string()),
  description: Yup.string(),
  urls: Yup.string(),
  contact: Yup.object().shape({
    name: Yup.string(),
    email: Yup.string().email(),
    phone: Yup.string(),
  }),
  paymentMethod: Yup.object().shape({
    type: Yup.string().oneOf(['PayPal', 'Venmo']),
    address: Yup.string(),
  }),
  location: Yup.object().shape({
    city: Yup.string(),
    state: Yup.string(),
  }),
});

interface IEditArtistModal {
  artist?: Artist;
  artistRef: firebase.firestore.DocumentReference;
  onClose: () => void;
  storageRef: firebase.storage.Reference;
}

export const EditArtistModal: React.FC<IEditArtistModal> = ({
  artist,
  artistRef,
  onClose,
  storageRef,
}) => {
  const { isAdmin } = useFanflexUser();
  const toast = useToast();
  const initialValues = getFormValuesFromArtist(artist);

  const onSubmit = async (values: any) => {
    try {
      await artistRef.set(values, { merge: true });
      toast({
        title: 'Successfully Updated!',
        status: 'success',
      });
      onClose();
    } catch (reason: any) {
      console.error(reason);
      toast({
        title: 'Uhoh. Something went wrong',
        description: reason.message,
        status: 'error',
      });
    }
  };

  const formik = useFormik<IArtistForm>({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const { name, avatarUri } = artist || {};

  return (
    <Portal>
      <FormikProvider value={formik}>
        <Modal isOpen onClose={onClose} size="xl" closeOnOverlayClick={false}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Artist Details</ModalHeader>
            <ModalBody>
              <VStack spacing={6} alignItems="flex-start">
                <Stack
                  spacing={6}
                  alignItems="center"
                  justifyContent="flex-start"
                  direction={{ base: 'column', lg: 'row' }}
                  width="100%"
                >
                  <Avatar size="2xl" name={name} src={avatarUri} />
                  <VStack spacing={6} width="100%">
                    <SimpleInput name="name" label="Artist Name" />
                    {isAdmin && (
                      <SimpleCheckbox
                        name="publiclyHidden"
                        label="Hide from Search"
                      />
                    )}
                    <SimpleInput name="slug" label="Username" isDisabled />
                    <GenreSelect
                      name="genres"
                      label="Genres"
                      placeholder="Select up to 3 genres"
                    />
                  </VStack>
                </Stack>
                <SimpleTextarea name="description" label="Biography" />
                <ProfileImagesEdit
                  documentRef={artistRef}
                  storageRef={storageRef}
                />
                <SimpleTextarea
                  name="urls"
                  label="Links"
                  helper="Add all your social and web links separated by a new line. URL must be entered like: 'https://www.instagram.com/username' or 'https://www.website.com'"
                />
                <HStack width="100%" spacing="4" pt="12" pb="0">
                  <Heading size="md">Contact Details</Heading>
                  <Divider flex="1" />
                </HStack>
                <Text fontSize="sm" opacity="0.5" pb="18">
                  This information is for FanFlex use only and will not be
                  published on your public profile.
                </Text>
                <SimpleInput name="contact.name" label="Full Name" />
                <SimpleInput name="contact.phone" label="Phone Number" />
                <SimpleInput name="contact.email" label="Email" />
                <Stack
                  direction={{ base: 'column', lg: 'row' }}
                  width="100%"
                  alignItems="flex-start"
                >
                  <SimpleSelect
                    name="paymentMethod.type"
                    label="Payout Method"
                    flex="1"
                  >
                    <option value="PayPal">PayPal</option>
                    <option value="Venmo">Venmo</option>
                  </SimpleSelect>
                  <SimpleInput
                    name="paymentMethod.address"
                    label={<>&nbsp;</>}
                    placeholder="username/email"
                    flex="2"
                  />
                </Stack>
                <Stack
                  direction={{ base: 'column', lg: 'row' }}
                  width="100%"
                  spacing="4"
                  alignItems="flex-start"
                >
                  <SimpleInput name="location.city" label="City" />
                  <SimpleSelect name="location.state" label="State">
                    {states.map(({ abbreviation, name }) => (
                      <option key={abbreviation} value={abbreviation}>
                        {name}
                      </option>
                    ))}
                  </SimpleSelect>
                </Stack>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button onClick={onClose} isLoading={formik.isSubmitting}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={formik.submitForm}
                  disabled={!formik.isValid}
                  isLoading={formik.isSubmitting}
                >
                  Save
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </FormikProvider>
    </Portal>
  );
};
