import { ILocation } from '../types/Location';

export const formatLocation = ({
  streetAddress,
  city,
  state,
}: ILocation): string => {
  if (streetAddress && city && state) {
    return `${streetAddress}\n${city}, ${state}`;
  }
  if (city && state) {
    return `${city}, ${state}`;
  }
  return '';
};
