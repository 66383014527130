import React, { useEffect } from 'react';
import firebase from 'firebase/app';

import {
  Button,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Select,
  useToast,
} from '@chakra-ui/react';
import {
  FormikHelpers,
  FormikProvider,
  useFormik,
  useFormikContext,
} from 'formik';
import {
  useCollectionDataOnce,
  useDocumentDataOnce,
} from 'react-firebase-hooks/firestore';
import { dateTimeFormatterInZone } from '../../lib/dateFormatter';
import * as Yup from 'yup';
import pick from 'lodash.pick';

import { DocumentReference } from '@firebase/firestore-types';

import { Artist } from '../../types/Artist';
import { Campaign } from '../../types/Campaign';
import { idAndRefFields } from '../../lib/idAndRefFields';
import { SimpleField } from '../SimpleField';
import { SimpleTextarea } from '../SimpleTextarea';
import { Slot } from '../../types/Slot';
import { Venue } from '../../types/Venue';
import { SimpleInput } from '../SimpleInput';

interface IEditCampaignModel {
  campaign?: Campaign;
  campaignRef: DocumentReference<Campaign>;
  header: string;
  onClose(): void;
}

export interface ICampaignForm {
  description?: string;
  title?: string;
  price?: number;
  slot?: string;
  venuePath?: string;
  artistPath?: string;
}

export const getFormValuesFromCampaign = (
  campaignRef: DocumentReference<Campaign>,
  campaign?: Campaign,
): ICampaignForm => ({
  description: campaign?.description,
  price: campaign?.price,
  slot: campaign?.slot?.path,
  title: campaign?.title,
  venuePath: campaign?.slot?.parent?.parent?.path,
  artistPath: campaignRef.parent?.parent?.path,
});

const validationSchema = Yup.object().shape({
  description: Yup.string().required(),
  price: Yup.number().required(),
  slot: Yup.string().required(),
  title: Yup.string().required(),
});

export const EditCampaignModal: React.FC<IEditCampaignModel> = ({
  onClose,
  campaign,
  campaignRef,
  header,
}): JSX.Element => {
  const initialValues = getFormValuesFromCampaign(campaignRef, campaign);
  const toast = useToast();

  const onSubmit = async (
    values: ICampaignForm,
    { setSubmitting }: FormikHelpers<ICampaignForm>,
  ) => {
    const createdAt =
      campaign?.createdAt || firebase.firestore.FieldValue.serverTimestamp();

    if (!values.slot) {
      throw Error('slot not defined');
    }

    try {
      await campaignRef.set(
        {
          ...pick(values, ['description', 'title']),
          price: Number(values.price),
          createdAt,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          slot: firebase.firestore().doc(values.slot),
        },
        { merge: true },
      );

      toast({
        title: 'Successfully Updated!',
        status: 'success',
      });
      onClose();
    } catch (reason: any) {
      console.error(reason);
      toast({
        title: 'Uhoh. Something went wrong',
        description: reason.message,
        status: 'error',
      });
    }

    setSubmitting(false);
  };

  const formik = useFormik<ICampaignForm>({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <Portal>
      <FormikProvider value={formik}>
        <Modal isOpen onClose={onClose} size="xl" closeOnOverlayClick={false}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{header}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <SimpleField name="venuePath" label="Venue">
                <VenueSelect name="venuePath" />
              </SimpleField>

              <SimpleField name="slot" label="Slot">
                <SlotSelect name="slot" />
              </SimpleField>

              <SimpleField name="title" label="Title">
                <CampaignTitleInput name="title" />
              </SimpleField>

              <SimpleTextarea name="description" label="Description" />

              <SimpleInput name="price" label="Ticket Price" />
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button onClick={onClose} isLoading={formik.isSubmitting}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={formik.submitForm}
                  disabled={!formik.isValid}
                  isLoading={formik.isSubmitting}
                >
                  Save
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </FormikProvider>
    </Portal>
  );
};

interface SlotSelectInputProps {
  name: string;
}

const nowish = firebase.firestore.Timestamp.now();

const SlotSelect: React.FC<SlotSelectInputProps> = ({ name }): JSX.Element => {
  const {
    getFieldProps,
    values: { venuePath },
  } = useFormikContext<EditCampaignModalFormInterface>();

  const venueRef = venuePath ? firebase.firestore().doc(venuePath) : undefined;

  const slotsRef = venueRef
    ? venueRef.collection('slots').orderBy('when').where('when', '>', nowish)
    : undefined;

  const [venue] = useDocumentDataOnce<Venue>(venueRef);
  const [slots] = useCollectionDataOnce<Slot, 'id', 'ref'>(
    slotsRef,
    idAndRefFields,
  );

  return (
    <Select {...getFieldProps(name)}>
      <option></option>
      {slots?.map((slot) => (
        <option key={slot.id} value={slot.ref.path}>
          {dateTimeFormatterInZone(venue?.location.timeZone).format(
            slot.when.toDate(),
          )}
        </option>
      ))}
    </Select>
  );
};

interface VenueSelectInputProps {
  name: string;
}

const VenueSelect: React.FC<VenueSelectInputProps> = ({
  name,
}): JSX.Element => {
  const { getFieldProps } = useFormikContext<EditCampaignModalFormInterface>();

  const [venues] = useCollectionDataOnce<Venue, 'id', 'ref'>(
    firebase.firestore().collection('venues').orderBy('name'),
    idAndRefFields,
  );

  return (
    <Select {...getFieldProps(name)}>
      <option></option>
      {venues?.map((venue) => (
        <option key={venue.id} value={venue.ref.path}>
          {venue.name}
        </option>
      ))}
    </Select>
  );
};

interface EditCampaignModalFormInterface {
  description: string;
  price: string;
  slot: string;
  title: string;
  venuePath: string;
  artistPath: string;
}

interface CampaignTitleInputProps {
  name: string;
}

const CampaignTitleInput: React.FC<CampaignTitleInputProps> = ({
  name,
}): JSX.Element => {
  const {
    touched,
    initialValues,
    setFieldValue,
    getFieldProps,
    values: { venuePath, artistPath },
  } = useFormikContext<EditCampaignModalFormInterface>();

  const [venue] = useDocumentDataOnce<Venue>(
    venuePath ? firebase.firestore().doc(venuePath) : undefined,
  );

  const [artist] = useDocumentDataOnce<Artist>(
    artistPath ? firebase.firestore().doc(artistPath) : undefined,
  );

  useEffect(() => {
    if (!artist?.name || !venue?.name) {
      return;
    }

    if (initialValues.title || touched.title) {
      return;
    }

    const title = `${artist?.name} LIVE at ${venue?.name}`;
    setFieldValue(name, title);
  }, [artist?.name, venue?.name]);

  return <Input {...getFieldProps(name)} />;
};
