import * as React from 'react';
import { useFormikContext } from 'formik';
import { Textarea } from '@chakra-ui/react';
import { SimpleField } from '../SimpleField';

export const SimpleTextarea: React.FC<any> = ({
  name,
  label,
  helper,
  ...rest
}) => {
  const { getFieldProps } = useFormikContext<any>();

  return (
    <SimpleField name={name} label={label} helper={helper} {...rest}>
      <Textarea {...getFieldProps(name)} rows={5} />
    </SimpleField>
  );
};
