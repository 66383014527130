import * as React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'focus-visible/dist/focus-visible';

import { css, Global } from '@emotion/react';
import {
  ChakraProvider,
  ColorModeScript,
  PortalManager,
} from '@chakra-ui/react';

import { chakraTheme } from './config';
import { Router } from './components/Router';
import * as serviceWorker from './serviceWorker';

const GlobalStyles = css`
  /*
    This will hide the focus indicator if the element receives focus via the mouse, but it will still show up on keyboard focus.
  */
  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }
  * { color-scheme: dark; }
  html, body, #root {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }
}
`;

ReactDOM.render(
  <React.StrictMode>
    <ColorModeScript initialColorMode={chakraTheme.config.initialColorMode} />
    <HelmetProvider>
      <ChakraProvider theme={chakraTheme}>
        <Global styles={GlobalStyles} />
        <PortalManager>
          <Router />
        </PortalManager>
      </ChakraProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorker.unregister();
