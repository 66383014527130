import {
  DocumentSnapshot,
  QueryDocumentSnapshot,
} from '@firebase/firestore-types';
import { Data } from 'react-firebase-hooks/firestore/dist/firestore/types';
import { Artist } from '../types/Artist';

export const artistURL = (slug: string, id: string): string =>
  `/artists/${slug || id}`;

export const artistURLData = (a: Data<Artist, 'id', 'ref'>): string =>
  artistURL(a.slug, a.id);

export const artistURLSnapshot = (
  a:
    | DocumentSnapshot
    | QueryDocumentSnapshot
    | DocumentSnapshot<Artist>
    | QueryDocumentSnapshot<Artist>,
): string => artistURL(a.get('slug'), a.id);
