import { Button, Icon, IconButton, Link } from '@chakra-ui/react';
import * as React from 'react';
import {
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoTwitter,
} from 'react-icons/io5';
import { findMatch, findNotSocialUrl, SOCIAL_URLS } from '../../lib/socialUrls';

const PRETTY_SOCIALS = {
  FB: 'Facebook',
  TW: 'Twitter',
  IG: 'Instagram',
  WEB: null,
};

type SocialTypes = 'WEB' | 'FB' | 'TW' | 'IG';

interface ISocialButtonProps {
  type?: SocialTypes;
  urls?: string;
  label?: string;
}
export const SocialButton: React.FC<ISocialButtonProps> = ({
  type = 'WEB',
  label: labelOverride,
  urls,
}) => {
  if (!urls || urls === '') {
    return null;
  }

  const isWeb = type === 'WEB';
  const href = isWeb
    ? findNotSocialUrl(urls)
    : findMatch?.((SOCIAL_URLS as any)?.[type])?.(urls);

  const label =
    labelOverride || PRETTY_SOCIALS[type] || getCleanHrefLabel(href);

  if (!href || !label) {
    return null;
  }

  if (isWeb) {
    return (
      <Button
        as={Link}
        href={href}
        isExternal
        style={{ textDecoration: 'none' }}
      >
        {label}
      </Button>
    );
  }

  const icon = getIcon(type);
  const network = PRETTY_SOCIALS[type] || 'Social Network';

  return (
    <IconButton
      as={Link}
      href={href}
      icon={icon}
      aria-label={network}
      isExternal
      style={{ textDecoration: 'none' }}
    />
  );
};

const getCleanHrefLabel = (label: string | undefined) =>
  label?.split('://')?.[1]?.split('/')?.[0];

const getIcon = (icon: SocialTypes) => {
  switch (icon) {
    case 'FB':
      return <Icon as={IoLogoFacebook} />;
    case 'TW':
      return <Icon as={IoLogoTwitter} />;
    case 'IG':
      return <Icon as={IoLogoInstagram} />;
  }
};
