export const timeZones = [
  {
    label: '(GMT-05:00) Eastern Time',
    tzCode: 'America/New_York',
  },
  {
    label: '(GMT-06:00) Central Time',
    tzCode: 'America/Chicago',
  },
  {
    label: '(GMT-07:00) Mountain Time',
    tzCode: 'America/Denver',
  },
  {
    label: '(GMT-08:00) Pacific Time',
    tzCode: 'America/Los_Angeles',
  },
  {
    label: '(GMT-09:00) Alaska Time',
    tzCode: 'America/Anchorage',
  },
];
