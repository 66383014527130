import { Center, Icon, Box } from '@chakra-ui/react';
import firebase from 'firebase/app';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { HiEyeOff } from 'react-icons/hi';
import { Carousel } from 'react-responsive-carousel';
import { CAROUSEL_KEY } from '../ProfileImagesEdit';

interface IProfileCarouselProps {
  documentRef: firebase.firestore.DocumentReference;
}

export const ProfileCarousel: React.FC<IProfileCarouselProps> = ({
  documentRef,
}) => {
  const [document] = useDocumentData(documentRef);
  const images = (document?.[CAROUSEL_KEY] || []) as string[];
  const noImages = images.length === 0;
  const manyImages = images.length > 1;

  return (
    <Box style={{ width: '100%' }} minHeight={{ base: 200, md: 300 }}>
      {noImages && (
        <Center height={{ base: 200, md: 300 }} bg="gray.900">
          <Icon as={HiEyeOff} boxSize="100" color="gray.800" />
        </Center>
      )}
      <Carousel
        autoPlay
        swipeable
        emulateTouch
        centerSlidePercentage={80}
        interval={4000}
        showStatus={false}
        showThumbs={false}
        centerMode={manyImages}
        infiniteLoop={manyImages}
        showIndicators={manyImages}
      >
        {images.map((node, index) => (
          <Box
            key={index}
            height={{ base: 200, md: 600 }}
            style={{
              backgroundImage: `url(${node})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
        ))}
      </Carousel>
    </Box>
  );
};
