import * as React from 'react';
import firebase from 'firebase/app';
import {
  FormControl,
  FormHelperText,
  Button,
  Flex,
  useDisclosure,
  Heading,
  VStack,
  HStack,
} from '@chakra-ui/react';
import TypesenseInstantsearchAdapter from 'typesense-instantsearch-adapter';
import { InstantSearch } from 'react-instantsearch-dom';

import { Page } from '../../../components/Page';
import { EditArtistModal } from '../../../components/EditArtistModal';
import { useExportUrl } from '../../../hooks/useExportUrl';
import { typesenseConfig } from '../../../config';
import { SearchInput } from '../../../components/SearchInput';
import { TableInfiniteHits } from '../../../components/TableInfiniteHits';

const typesenseInstantsearchAdapter = new TypesenseInstantsearchAdapter({
  server: {
    ...typesenseConfig,
    cacheSearchResultsForSeconds: 2 * 60,
  },
  additionalSearchParameters: {
    queryBy: 'name,city,state,contactName,contactEmail,contactPhone,genres',
    numTypos: 0,
  },
});
const searchClient = typesenseInstantsearchAdapter.searchClient;

export const AdminArtistListScreen: React.FC<any> = () => {
  const artistRef = firebase.firestore().collection('artists').doc();
  const storageRef = firebase.storage().ref(`artists/${artistRef.id}`);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const exportArtistsUrl = useExportUrl('exportArtists');

  return (
    <Page title="Artists • Admin">
      <VStack maxWidth="1200px" mx="auto" spacing="2" mt="2">
        <Flex w="100%" p="2" justifyContent={'space-between'}>
          <Heading>Artists</Heading>
          <HStack>
            <Button onClick={onOpen}>Create Artist</Button>
            <Button as="a" href={exportArtistsUrl}>
              Export All
            </Button>
          </HStack>
        </Flex>
        <InstantSearch indexName="artists" searchClient={searchClient}>
          <FormControl p="2">
            <SearchInput placeholder="Search for Artists" />
            <FormHelperText>
              You can search by artist name, contact name, email, phone number,
              city, state, or genres.
            </FormHelperText>
          </FormControl>
          <TableInfiniteHits urlBase="artists" />
        </InstantSearch>
      </VStack>

      {isOpen && (
        <EditArtistModal
          artistRef={artistRef}
          onClose={onClose}
          storageRef={storageRef}
        />
      )}
    </Page>
  );
};
