import firebase from 'firebase/app';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { Data } from 'react-firebase-hooks/firestore/dist/firestore/types';
import { useParams } from 'react-router-dom';

import { Venue } from '../types/Venue';

interface IUseVenueScreenData {
  isLoading: boolean;

  venueId: string;
  venue?: Data<Venue, '', ''>;

  storageRef: firebase.storage.Reference;
  documentsRef: firebase.storage.Reference;
  venueRef: firebase.firestore.DocumentReference<Venue>;
}

interface Params {
  venueId: string;
}

export function useVenueScreenData(): IUseVenueScreenData {
  const { venueId } = useParams<Params>();

  const venueRef = firebase
    .firestore()
    .doc(`/venues/${venueId}`) as firebase.firestore.DocumentReference<Venue>;

  const [venue, isLoading] = useDocumentData<Venue>(venueRef);

  const storageRef = firebase.storage().ref(`venues/${venueId}`);
  const documentsRef = storageRef.child('documents');

  return {
    isLoading,

    venue,
    venueId,

    storageRef,
    documentsRef,
    venueRef,
  };
}
