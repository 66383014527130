import * as React from 'react';
import { useFormikContext } from 'formik';
import { Checkbox } from '@chakra-ui/react';
import { SimpleField } from '../SimpleField';

export const SimpleCheckbox: React.FC<any> = ({
  name,
  label,
  helper,
  ...rest
}) => {
  const { getFieldProps } = useFormikContext<any>();
  const props = getFieldProps(name);

  return (
    <SimpleField name={name} label={''} helper={helper} {...rest}>
      <Checkbox {...props} defaultChecked={props?.value}>
        {label}
      </Checkbox>
    </SimpleField>
  );
};
