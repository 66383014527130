import { Artist } from '../types/Artist';
import { IRoles } from '../types/Role';
import { useFanflexUser } from './useFanflexUser';

export enum Actions {
  'Create' = 'CREATE',
  'Read' = 'READ',
  'Update' = 'UPDATE',
  'Destroy' = 'DESTROY',
  'None' = 'NONE',
}

type Action = Actions;
type Resource = Artist;
type IPermissions = {
  [key in IRoles]: Action[];
};

const PERMISSIONS: IPermissions = {
  [IRoles.Owner]: [Actions.Create, Actions.Read, Actions.Update],
  [IRoles.Reader]: [Actions.Read],
};

interface IUseAuthorization {
  can: (action: Action, resource: Resource) => boolean;
  canCreate: (resource: Resource) => boolean;
  canRead: (resource: Resource) => boolean;
  canUpdate: (resource: Resource) => boolean;
  canDestroy: (resource: Resource) => boolean;
}

export const useAuthorization = (): IUseAuthorization => {
  const { user, isAdmin } = useFanflexUser();

  const can = (action: Action, resource: Resource): boolean => {
    // Check if the public can do this or not
    const publicRole = resource?.roles?.public || IRoles.Reader;
    const anyoneCan = PERMISSIONS[publicRole]?.includes(action);
    if (anyoneCan) return true;

    // If they can't, ensure we have a user or fail
    if (!user?.uid) return false;

    // Allow admins to do it all
    if (isAdmin) return true;

    // Check if the user has a role
    const userRole = resource?.roles?.[user?.uid];
    if (!userRole) return false;

    // Check if the user has the permissions
    const userCan = PERMISSIONS[userRole]?.includes(action);
    if (userCan) return true;

    // Default to nope
    return false;
  };

  const canCreate = (resource: Resource) => can(Actions.Create, resource);
  const canRead = (resource: Resource) => can(Actions.Read, resource);
  const canUpdate = (resource: Resource) => can(Actions.Update, resource);
  const canDestroy = (resource: Resource) => can(Actions.Destroy, resource);

  return {
    can,
    canCreate,
    canRead,
    canUpdate,
    canDestroy,
  };
};
