import { Heading } from '@chakra-ui/layout';
import {
  Button,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Spinner,
  useToast,
  VStack,
} from '@chakra-ui/react';
import firebase from 'firebase';
import * as Yup from 'yup';
import { FormikProvider, useFormik, useFormikContext } from 'formik';
import React, { FormEvent, useCallback } from 'react';
import debounce from 'lodash.debounce';

import { HiX, HiCheck } from 'react-icons/hi';

import { Card } from '../../components/Card';
import { Page } from '../../components/Page';
import { SimpleInput } from '../../components/SimpleInput';
import { SimpleField } from '../../components/SimpleField';
import { useHistory } from 'react-router-dom';

const initialValues = {
  name: '',
  slug: '',
};

const checkUsername = firebase
  .functions()
  .httpsCallable('https-checkArtistSlugAvailability');

const createArtist = firebase.functions().httpsCallable('https-createArtist');

export const ArtistNewScreen: React.FC = () => {
  const history = useHistory();
  const toast = useToast();

  const artistSlugValidation = async (
    slug: string,
    resolve: any,
  ): Promise<void> => {
    try {
      const { data } = await checkUsername({ slug });
      resolve(data?.isAvailable || false);
    } catch (error) {
      resolve(false);
    }
  };

  const debouncedArtistSlugValidation = useCallback(
    debounce(artistSlugValidation, 150),
    [],
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    slug: Yup.string()
      .required('Username is required')
      .matches(
        new RegExp(/^([A-Za-z0-9\-_]+)$/i),
        'Usernames can only contain letters, numbers, underscores, and dashes',
      )
      .test(
        'is-unique-artist-slug',
        'Username is already taken.',
        (val: any) =>
          !!val &&
          new Promise((resolve) => debouncedArtistSlugValidation(val, resolve)),
      ),
  });

  const handleError = (description: string) =>
    toast({ status: 'error', title: 'Uh oh!', description });

  const onSubmit = async (values: any) => {
    try {
      const { name, slug } = values;

      await createArtist({ name, slug });

      history.push(`/artists/${slug}/edit`);
    } catch ({ message }) {
      handleError(message);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnChange: true,
    validateOnBlur: false,
  });

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    formik.submitForm();
  };

  return (
    <Page title="Create an artist profile">
      <VStack minH="85vh" flex="1" alignItems="center" pt="20">
        <Card maxW="100%" w="400px">
          <FormikProvider value={formik}>
            <Heading size="lg">Create an artist profile</Heading>
            <form onSubmit={handleSubmit}>
              <VStack spacing={6}>
                <SimpleInput label="Artist name" name="name" size="lg" />
                <SimpleField name="slug" label="Username">
                  <ArtistSlugInput name="slug" size="lg" />
                </SimpleField>
                <Button
                  width="100%"
                  variant="primary"
                  size="lg"
                  onClick={formik.submitForm}
                  disabled={!formik.isValid}
                  isLoading={formik.isSubmitting}
                >
                  Continue
                </Button>
              </VStack>
            </form>
          </FormikProvider>
        </Card>
      </VStack>
    </Page>
  );
};

const ArtistSlugInput: React.FC<any> = ({ name }): React.ReactElement => {
  const { getFieldProps, isValidating, errors, values } = useFormikContext();

  const hasValue = (values as any)[name];
  const isValid = !(errors as any)[name];

  const icon = () => {
    if (isValidating) {
      return <Spinner size="sm" />;
    }

    if (isValid) {
      return <HiCheck color="green.500" />;
    }

    return <HiX color="red.900" />;
  };

  return (
    <InputGroup {...getFieldProps(name)} size="lg">
      <InputLeftAddon children="@" />
      <Input placeholder="username" />
      {hasValue && <InputRightElement children={icon()} />}
    </InputGroup>
  );
};
