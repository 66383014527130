import firebase from 'firebase';
import { useState } from 'react';
import { useParams, Link as RRLink } from 'react-router-dom';

import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Stack,
  Text,
  useNumberInput,
  useToast,
  VStack,
} from '@chakra-ui/react';

import {
  HiOutlineClock,
  HiOutlineEmojiHappy,
  HiOutlineEmojiSad,
  HiOutlineLocationMarker,
  HiOutlineMinus,
  HiOutlinePlus,
  HiOutlineTicket,
} from 'react-icons/hi';

import { stripePromise } from '../../config';
import FORMATS from '../../lib/dateTimeFormats';

import { useArtistCampaignSearch } from '../../hooks/useArtistCampaignSearch';
import { useFanflexUser } from '../../hooks/useFanflexUser';
import { useRouteHelp } from '../../hooks/useRouteHelp';

import { Card } from '../../components/Card';
import { EditCampaignModal } from '../../components/EditCampaignModal';
import { Page } from '../../components/Page';
import { IGetCheckoutSessionParams } from '../../types/SharedInterfaces';
import { useDateFormatterInVenueZone } from '../../hooks/useDateFormatterInVenueZone';
import { IconText } from '../../components/IconText';
import { artistURLSnapshot } from '../../lib/artist';

interface IArtistCampaignScreen {
  artistId: string;
  campaignId: string;
}

export const ArtistCampaignScreen: React.FC = () => {
  const { artistId, campaignId } = useParams<IArtistCampaignScreen>();

  const { isAdmin, user } = useFanflexUser();
  const { toEdit, isEdit, replaceWithoutEdit } = useRouteHelp();

  const isSuccess = location.pathname.endsWith('/success');
  const isCancel = location.pathname.endsWith('/cancel');
  const isRoot = !isSuccess && !isCancel;

  const [isFetchingLink, setIsFetchingLink] = useState<boolean>(false);
  const toast = useToast({
    position: 'bottom',
    duration: 9000,
    isClosable: true,
  });

  const {
    campaignSnap,
    artistSnap,
    venueSnap,
    slotSnap,
    isLoading,
  } = useArtistCampaignSearch(artistId, campaignId);

  const campaign = campaignSnap?.data();
  const venue = venueSnap?.data();
  const slot = slotSnap?.data();

  const {
    value: quantity,
    getIncrementButtonProps,
    getDecrementButtonProps,
  } = useNumberInput({
    step: 1,
    defaultValue: 1,
    min: 1,
    max: 30,
    precision: 0,
  });

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();

  if (isLoading) {
    return (
      <Page hideHeader>
        <Flex minH="85vh" p={3} justifyContent="center" alignItems="center">
          <Card isLoading={true} maxWidth="600px" width="100vw" />
        </Flex>
      </Page>
    );
  }

  if (!campaignSnap || !campaign || !venue || !slot) {
    return (
      <Page title="Show Not Found" hideHeader={!user}>
        <Flex minH="85vh" p={3} justifyContent="center" alignItems="center">
          <Card maxWidth="600px" width="100vw">
            <Text alignSelf="center" pt="10" pb="10">
              We couldn't find this campaign.
            </Text>
          </Card>
        </Flex>
      </Page>
    );
  }

  const handleClick = async (event: any) => {
    try {
      event.preventDefault();

      setIsFetchingLink(true);
      const stripe = await stripePromise;

      if (!stripe) {
        // not sure when this would happen — but need this to appease typescript
        return toast({
          title: 'Something went wrong.',
          description: 'Please try again!',
          status: 'error',
        });
      }

      const getCheckoutSession = firebase
        .functions()
        .httpsCallable('https-getCheckoutSession');

      const params: IGetCheckoutSessionParams = {
        quantity: Number(quantity),
        baseUrl: window.location.href,
        campaignId: campaignSnap.ref.path,
      };

      const {
        data: { id: sessionId },
      } = await getCheckoutSession(params);

      const result = await stripe.redirectToCheckout({ sessionId });
      if (result.error) {
        toast({
          title: 'Something went wrong.',
          description: result?.error?.message,
          status: 'error',
        });
      }
    } catch (error: any) {
      console.log(error);
      toast({
        title: 'Something went wrong.',
        description: error?.message,
        status: 'error',
      });
    } finally {
      setIsFetchingLink(false);
    }
  };

  const headerActions = isRoot && isAdmin && (
    <Button as={RRLink} variant="primary" size="md" replace to={toEdit}>
      Edit
    </Button>
  );

  const content = (
    <>
      {isRoot && (
        <VStack pt="4" pb="4" pl="4" pr="4" alignItems="flex-start">
          <Text fontSize="22px" fontWeight="bold">
            {campaign.title}
          </Text>
          <Text pt="2" fontSize="16px" opacity="0.5" lineHeight="180%">
            {campaign.description}
          </Text>
        </VStack>
      )}
      {isSuccess && (
        <VStack mt="8" mb="16" pl="3" pr="3" textAlign="center">
          <Box color="brand.500" mb="8">
            <HiOutlineEmojiHappy size="200" />
          </Box>
          <Text fontSize="26px" fontWeight="bold">
            You’re going to the show!
          </Text>
          <Text pt="2" fontSize="16px" opacity="0.5" lineHeight="180%">
            {campaign?.title}
          </Text>
        </VStack>
      )}
      {isCancel && (
        <VStack mt="8" mb="16" pl="3" pr="3" textAlign="center">
          <Box color="brand.500" mb="8">
            <HiOutlineEmojiSad size="200" />
          </Box>
          <Text fontSize="26px" fontWeight="bold">
            It’s not too late!
          </Text>
          <Text pt="2" fontSize="16px" opacity="0.5" lineHeight="180%">
            You can still get a ticket to
            <br />
            {campaign?.title}
          </Text>
        </VStack>
      )}
    </>
  );

  const actions = (
    <>
      {isRoot && (
        <HStack spacing="3">
          <Button {...dec} size="lg">
            <HiOutlineMinus size={20} />
          </Button>
          <Button
            flex="4"
            variant="primary"
            size="lg"
            isLoading={isFetchingLink}
            onClick={handleClick}
          >
            Buy {quantity} Ticket{Number(quantity) > 1 ? 's' : ''}
          </Button>
          <Button {...inc} size="lg">
            <HiOutlinePlus size={20} />
          </Button>
        </HStack>
      )}
      {isCancel && artistSnap && (
        <Button
          to={`${artistURLSnapshot(artistSnap)}/campaigns/${campaignId}`}
          as={RRLink}
          variant="primary"
          size="lg"
        >
          Buy a Ticket
        </Button>
      )}
    </>
  );

  const when = slot.when.toDate();
  const dateFormatter = useDateFormatterInVenueZone(venue);
  const coverImageUrl = campaign.images?.[0];

  return (
    <>
      {isEdit && isAdmin && (
        <EditCampaignModal
          header="Edit Campaign"
          campaignRef={campaignSnap.ref}
          campaign={campaign}
          onClose={() => {
            replaceWithoutEdit();
            location.reload();
          }}
        />
      )}

      <Page title={campaign.title} hideHeader={!user}>
        <Flex
          minH="85vh"
          justifyContent="center"
          alignItems="center"
          p="10"
          pt="0"
        >
          <Card maxWidth="600px" width="100vw">
            {headerActions}
            {coverImageUrl && <Image src={coverImageUrl} borderRadius="8px" />}
            {content}
            <Stack
              pt="1"
              pb="3"
              flex="1"
              justify="space-between"
              alignItems="center"
              flexDir={{ base: 'column', md: 'row' }}
            >
              <IconText
                icon={<HiOutlineTicket size="30" />}
                lineOne={`$${campaign.price}`}
                lineTwo="USD"
              />
              <IconText
                icon={<HiOutlineLocationMarker size="30" />}
                lineOne={venue.name}
                lineTwo={`${venue.location?.city}, ${venue.location?.state}`}
              />
              <IconText
                icon={<HiOutlineClock size="30" />}
                lineOne={dateFormatter(FORMATS.DATE, when)}
                lineTwo={dateFormatter(FORMATS.TIME, when)}
              />
            </Stack>
            {actions}
          </Card>
        </Flex>
      </Page>
    </>
  );
};
