import { Data } from 'react-firebase-hooks/firestore/dist/firestore/types';

import {
  useCollectionData,
  useDocumentData,
} from 'react-firebase-hooks/firestore';

import {
  DocumentReference,
  CollectionReference,
} from '@firebase/firestore-types';

import { idAndRefFields } from '../lib/idAndRefFields';

import { Artist } from '../types/Artist';
import { Campaign } from '../types/Campaign';
import { Slot } from '../types/Slot';
import { Venue } from '../types/Venue';
import { Ticket } from '../types/Ticket';

interface IrelativeDocumentRefs {
  artist?: DocumentReference | null;
  slot?: DocumentReference;
  tickets?: CollectionReference;
  venue?: DocumentReference | null;
}

export const relativeDocumentRefs = (
  c: Data<Campaign, 'id', 'ref'> | undefined,
): IrelativeDocumentRefs => ({
  artist: c?.ref.parent.parent,
  slot: c?.slot,
  tickets: c?.ref.collection('tickets'),
  venue: c?.slot.parent.parent,
});

interface DocumentsRelatedToCampaign {
  artist?: Data<Artist, 'id', 'ref'>;
  slot?: Data<Slot, 'id', 'ref'>;
  tickets?: Data<Ticket, 'id', 'ref'>[];
  venue?: Data<Venue, 'id', 'ref'>;
  isLoading: boolean;
}

export const useDocumentsRelatedToCampaign = (
  campaign: Data<Campaign, 'id', 'ref'> | undefined,
): DocumentsRelatedToCampaign => {
  const refs = relativeDocumentRefs(campaign);

  const [artist, artistLoading] = useDocumentData<Artist, 'id', 'ref'>(
    refs.artist,
    idAndRefFields,
  );

  const [slot, slotLoading] = useDocumentData<Slot, 'id', 'ref'>(
    refs.slot,
    idAndRefFields,
  );
  const [venue, venueLoading] = useDocumentData<Venue, 'id', 'ref'>(
    refs.venue,
    idAndRefFields,
  );

  const [tickets, ticketsLoading] = useCollectionData<Ticket, 'id', 'ref'>(
    refs.tickets,
    idAndRefFields,
  );

  return {
    venue,
    tickets,
    slot,
    artist,
    isLoading: artistLoading || slotLoading || ticketsLoading || venueLoading,
  };
};
