import { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import { config } from '../config';

export function useExportUrl(name: string): string {
  const [url, setUrl] = useState('');

  useEffect(() => {
    firebase
      .auth()
      .currentUser?.getIdToken()
      .then((token) => {
        setUrl(
          `https://us-central1-${config.firebase.projectId}.cloudfunctions.net/https-${name}?token=${token}`,
        );
      });
  }, []);

  return url;
}
