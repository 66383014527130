import { Text } from '@chakra-ui/layout';
import * as React from 'react';

export const ParagraphedText: React.FC = ({ children, ...rest }) => {
  if (!children) {
    return null;
  }

  return (
    <>
      {(children as string)?.split(/\n/).map((paragraph, i) => (
        <Text key={`para-${i}`} {...rest}>
          {paragraph}
        </Text>
      ))}
    </>
  );
};
