import * as React from 'react';
import { Helmet } from 'react-helmet-async';

import { Footer } from '../Footer';
import { Header } from '../Header';

interface IPageProps {
  title?: string;
  hideFooter?: boolean;
  hideHeader?: boolean;
  hideLoginButton?: boolean;
  fixedHeader?: boolean;
  lockScroll?: boolean;
}

export const Page: React.FC<IPageProps> = ({
  title,
  children,
  hideHeader = false,
  hideFooter = false,
  hideLoginButton = false,
  fixedHeader = false,
  lockScroll = false,
}) => {
  const overflow = lockScroll ? 'hidden' : 'auto';

  return (
    <div style={{ height: '100vh', width: '100vw', overflow }}>
      <Helmet>
        <title>{title ? `${title} • ` : ''}FanFlex</title>
      </Helmet>
      {!hideHeader && (
        <Header fixedHeader={fixedHeader} hideLoginButton={hideLoginButton} />
      )}
      {children}
      {!hideFooter && <Footer />}
    </div>
  );
};
