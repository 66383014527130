import { VStack } from '@chakra-ui/layout';
import { StackProps } from '@chakra-ui/react';
import { Spinner } from '@chakra-ui/spinner';
import * as React from 'react';

interface CardProps extends StackProps {
  isLoading?: boolean;
}

export const Card: React.FC<CardProps> = ({ isLoading, children, ...rest }) => (
  <VStack
    spacing={6}
    p="6"
    alignItems="initial"
    borderRadius="8px"
    backgroundColor="gray.700"
    boxShadow="xl"
    {...rest}
  >
    {isLoading ? (
      <Spinner alignSelf="center" mt="12" mb="12" color="brand.500" />
    ) : (
      children
    )}
  </VStack>
);
