import { Link as RouterLink, useParams } from 'react-router-dom';
import { usePagination } from 'use-pagination-firestore';
import {
  CollectionReference,
  QueryDocumentSnapshot,
  DocumentReference,
} from '@firebase/firestore-types';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';

import {
  Table,
  Tbody,
  Td,
  Tr,
  Thead,
  Th,
  Link,
  Button,
  Flex,
  VStack,
  Heading,
  useDisclosure,
} from '@chakra-ui/react';

import { Campaign } from '../../types/Campaign';
import { campaignURL } from '../../lib/campaign';
import { EditCampaignModal } from '../../components/EditCampaignModal';
import { idAndRefFields } from '../../lib/idAndRefFields';
import { Page } from '../../components/Page';
import { useDateFormatterInVenueZone } from '../../hooks/useDateFormatterInVenueZone';
import { useDocumentsRelatedToCampaign } from '../../hooks/useDocumentsRelatedToCampaign';
import { useFanflexUser } from '../../hooks/useFanflexUser';
import FORMATS from '../../lib/dateTimeFormats';
import { artistURLData } from '../../lib/artist';
import { useArtistSearch } from '../../hooks/useArtistSearch';
import { Artist } from '../../types/Artist';
import { WantToBookAShowMessage } from '../../components/WantToBookAShowMessage';

interface ArtistCampaignListRow {
  campaignRef: QueryDocumentSnapshot;
}

const ArtistCampaignListRow: React.FC<ArtistCampaignListRow> = ({
  campaignRef,
}) => {
  const [campaign] = useDocumentDataOnce<Campaign, 'id', 'ref'>(
    campaignRef.ref,
    idAndRefFields,
  );

  const { artist, venue } = useDocumentsRelatedToCampaign(campaign);

  const dateFormatter = useDateFormatterInVenueZone(venue);
  const when = campaign?._when?.toDate();

  return (
    <Tr>
      <Td>
        {campaign && artist && (
          <Link as={RouterLink} to={campaignURL(campaign, artist)}>
            {campaign.title}
          </Link>
        )}
      </Td>
      <Td>
        {venue && (
          <>
            {venue.location.city}, {venue.location.state}
          </>
        )}
      </Td>
      <Td>
        {campaign && artist && (
          <Link
            as={RouterLink}
            to={`${artistURLData(artist)}/campaigns/${campaign?.id}/tickets`}
          >
            {campaign.totalTicketsSold > 0
              ? `Tickets sold: ${campaign.totalTicketsSold}`
              : 'No tickets sold'}
          </Link>
        )}
      </Td>
      <Td>{when && dateFormatter(FORMATS.DATETIME, when)}</Td>
    </Tr>
  );
};

interface IArtistCampaignListTable {
  campaignsRef: CollectionReference;
}

const ArtistCampaignListTable: React.FC<IArtistCampaignListTable> = ({
  campaignsRef,
}) => {
  const {
    docs,
    isStart,
    isEnd,
    getPrev,
    getNext,
    isLoading,
  } = usePagination<Campaign>(campaignsRef?.orderBy('_when', 'desc'), {
    limit: 10,
  });

  const disablePrev = isStart || docs.length === 0;

  return (
    <>
      <Table>
        <Thead>
          <Tr>
            <Th>Title</Th>
            <Th>Location</Th>
            <Th>Status</Th>
            <Th>When</Th>
          </Tr>
        </Thead>
        <Tbody>
          {docs.map((campaignRef, i) => (
            <ArtistCampaignListRow campaignRef={campaignRef} key={i} />
          ))}
        </Tbody>
      </Table>
      <Flex width="100%" p={2} justifyContent="space-between">
        <Button
          size="sm"
          disabled={disablePrev}
          isLoading={isLoading}
          onClick={getPrev}
        >
          Previous Page
        </Button>
        <Button
          size="sm"
          disabled={isEnd}
          isLoading={isLoading}
          onClick={getNext}
        >
          Next Page
        </Button>
      </Flex>
    </>
  );
};

interface IArtistCampaignListScreen {
  artistId: string;
}

export const ArtistCampaignListScreen: React.FC = () => {
  const { isAdmin, managedArtist } = useFanflexUser();
  const { artistId } = useParams<IArtistCampaignListScreen>();
  const { artistSnap } = useArtistSearch(artistId);
  const artist = artistSnap?.data as Artist | undefined;

  const { isOpen, onOpen, onClose } = useDisclosure();
  const campaignsRef = artistSnap?.ref.collection('campaigns');
  const newCampaignRef = campaignsRef?.doc() as DocumentReference<Campaign>;

  return (
    <>
      {isOpen && newCampaignRef && (
        <EditCampaignModal
          header="Create Campaign"
          onClose={onClose}
          campaignRef={newCampaignRef}
        />
      )}
      <Page title="Campaigns">
        <VStack marginX="auto" maxW="1000" alignSelf="center" pt="10">
          {artist?.name && (
            <Heading size="md">
              Campaigns for <i>{artist?.name}</i>
            </Heading>
          )}

          {isAdmin && (
            <Flex width="100%" p={2} justifyContent="space-between">
              <Button onClick={onOpen}>Create Campaign</Button>
            </Flex>
          )}

          {campaignsRef && (
            <ArtistCampaignListTable campaignsRef={campaignsRef} />
          )}

          {(isAdmin || managedArtist?.id == artistSnap?.id) && (
            <WantToBookAShowMessage />
          )}
        </VStack>
      </Page>
    </>
  );
};
