import { Box, Flex, Text, VStack } from '@chakra-ui/layout';
import * as React from 'react';

interface IconTextProps {
  // eslint-disable-next-line
  icon: React.ReactElement<any>;
  lineOne: string;
  lineTwo: string;
}
export const IconText: React.FC<IconTextProps> = ({
  icon,
  lineOne,
  lineTwo,
}) => (
  <Flex
    flexDir={{ base: 'row' }}
    alignItems={{ base: 'center' }}
    mt="0 !important"
  >
    <Box color="brand.500">{icon}</Box>
    <VStack pl="3" align="left">
      <Text fontWeight="black">{lineOne}</Text>
      <Text mt="-4px !important">{lineTwo}</Text>
    </VStack>
  </Flex>
);
