import * as React from 'react';
import { Link as RRLink } from 'react-router-dom';
import {
  HStack,
  VStack,
  Heading,
  Button,
  Spacer,
  Box,
  ListItem,
  Text,
  ListIcon,
  List,
} from '@chakra-ui/react';

import { useFanflexUser } from '../../hooks/useFanflexUser';
import { useRouteHelp } from '../../hooks/useRouteHelp';
import { useVenueScreenData } from '../../hooks/useVenueScreenData';

import { Card } from '../../components/Card';
import { DetailPage } from '../../components/DetailPage';
import { DocumentsSection } from '../../components/DocumentsSection';
import { EditVenueModal } from '../../components/EditVenueModal';
import { RequestToBookForm } from '../../components/RequestToBookForm';
import { UpcomingSlots } from '../../components/UpcomingSlots';
import { ParagraphedText } from '../../components/ParagraphedText';
import { formatLocation } from '../../lib/formatLocation';
import { CheckIcon } from '@chakra-ui/icons';

export const VenueScreen: React.FC = () => {
  const {
    isLoading: userIsLoading,
    isAdmin,
    managedArtist,
    user,
  } = useFanflexUser();
  const { isEdit, toEdit, replaceWithoutEdit } = useRouteHelp();
  const {
    isLoading: venueIsLoading,
    venue,
    venueRef,
    storageRef,
    documentsRef,
  } = useVenueScreenData();

  const isLoading = userIsLoading || venueIsLoading;

  if (isLoading || !venue) {
    return null;
  }

  if (!isLoading && !isAdmin && isEdit) {
    replaceWithoutEdit();
  }

  const subheading = formatLocation(venue?.location);

  return (
    <>
      {isEdit && isAdmin && (
        <EditVenueModal
          header="Edit Venue"
          venue={venue}
          venueRef={venueRef}
          storageRef={storageRef}
          onClose={replaceWithoutEdit}
        />
      )}
      <DetailPage
        heading={venue?.name || ''}
        subheading={subheading}
        urls={venue?.urls}
        documentRef={venueRef}
      >
        <HStack
          spacing="8"
          pt="10"
          pb="10"
          maxW="1000"
          width="100%"
          alignItems="flex-start"
          flexDirection={{ base: 'column', lg: 'row' }}
        >
          <VStack alignItems="flex-start" flex={3} spacing={3}>
            <Card maxWidth="initial" width="100%">
              <ParagraphedText>{venue?.description}</ParagraphedText>
            </Card>
            {isAdmin && (
              <Card maxWidth="initial" width="100%">
                <HStack alignItems="flex-start" justifyContent="space-between">
                  <Heading size="lg">Manage</Heading>
                  <Button
                    as={RRLink}
                    variant="primary"
                    size="md"
                    replace
                    to={toEdit}
                  >
                    Edit Venue
                  </Button>
                </HStack>
                <Spacer height="6" />
                <DocumentsSection
                  documentsRef={documentsRef}
                  title="Tech Specs & Venue Details"
                />
                <Spacer height="6" />
                <UpcomingSlots venueRef={venueRef} />
              </Card>
            )}
          </VStack>

          {!user && (
            <Box padding={4}>
              <VStack spacing={4}>
                <Heading size="md">Sign up for FanFlex</Heading>
                <VStack spacing={2}>
                  <Text>As an Artist on FanFlex you'll be able to:</Text>
                  <List spacing={1}>
                    <ListItem>
                      <ListIcon as={CheckIcon} color="green" />
                      See Venue availability
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckIcon} color="green" />
                      Request to book shows
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckIcon} color="green" />
                      Sell tickets to shows
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckIcon} color="green" />
                      Access fan data
                    </ListItem>
                  </List>
                </VStack>
                <Button as={RRLink} to="/signup" variant="primary" size="lg">
                  Sign up today!
                </Button>
              </VStack>
            </Box>
          )}

          {managedArtist && user && (
            <VStack alignItems="flex-start" flex={2}>
              <Card maxWidth="initial" width="100%">
                <Heading size="md">Request to book a show</Heading>
                <RequestToBookForm
                  artistRef={managedArtist.ref}
                  venueRef={venueRef}
                  uid={user.uid}
                />
              </Card>
            </VStack>
          )}
        </HStack>
      </DetailPage>
    </>
  );
};
