import firebase from 'firebase/app';
import {
  Heading,
  Divider,
  List,
  ListItem,
  ListIcon,
  Link,
  Button,
  HStack,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Popover,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  useToast,
} from '@chakra-ui/react';
import { HiPaperClip, HiPlus, HiTrash } from 'react-icons/hi';
import { Uploader } from '../Uploader';
import { useDocumentsByRef } from '../../hooks/useDocumentsByRef';
interface Props {
  documentsRef: firebase.storage.Reference;
  title?: string;
}

export const DocumentsSection: React.FC<Props> = ({
  documentsRef,
  title = 'Documents',
}) => {
  const { documents, refresh } = useDocumentsByRef(documentsRef);
  const toast = useToast();

  const onUploadComplete = () => {
    toast({ title: 'File Uploaded', status: 'success' });
    refresh();
  };

  const remove = async (document: firebase.storage.Reference) => {
    await document.delete();
    refresh();
  };

  return (
    <>
      <HStack alignItems="flex-start" justifyContent="space-between">
        <Heading size="md">{title}</Heading>
        {documentsRef && (
          <Popover>
            <PopoverTrigger>
              <Button
                size="sm"
                leftIcon={<HiPlus />}
                colorScheme="teal"
                variant="ghost"
              >
                Add File
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>Upload File</PopoverHeader>
              <PopoverBody>
                <Uploader
                  storageRef={documentsRef}
                  onComplete={onUploadComplete}
                />
              </PopoverBody>
            </PopoverContent>
          </Popover>
        )}
      </HStack>

      <List spacing={3}>
        {documents?.map(({ downloadURL, name, ref }, idx) => (
          <ListItem key={`${name}-${idx}`}>
            <HStack width="100%">
              <ListIcon as={HiPaperClip} color="green.500" />
              <Link
                isExternal
                href={downloadURL}
                minWidth={0}
                flex={1}
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '10px',
                }}
              >
                {name}
              </Link>
              <Button
                size="xs"
                leftIcon={<HiTrash />}
                colorScheme="red"
                variant="ghost"
                alignSelf="flex-end"
                onClick={() => remove(ref)}
              >
                Remove
              </Button>
            </HStack>
            {documents.length - 1 !== idx && <Divider mt="3" />}
          </ListItem>
        ))}
      </List>
    </>
  );
};
