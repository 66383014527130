import { Artist } from '../types/Artist';
import { Venue } from '../types/Venue';

export interface IArtistForm {
  name?: string;
  publiclyHidden?: boolean;
  slug?: string;
  genres?: string[];
  description?: string;
  urls?: string;
  avatarUri?: string;
  location: {
    streetAddress?: string;
    city?: string;
    state?: string;
  };
  contact: {
    name?: string;
    email?: string;
    phone?: string;
  };
  paymentMethod: {
    type?: string;
    address?: string;
  };
}

export const getFormValuesFromArtist = (artist?: Artist): IArtistForm => ({
  name: artist?.name || '',
  publiclyHidden: artist?.publiclyHidden || false,
  slug: artist?.slug || '',
  genres: artist?.genres || [],
  description: artist?.description || '',
  urls: artist?.urls || '',
  avatarUri: artist?.avatarUri || '',
  location: {
    streetAddress: artist?.location?.streetAddress || '',
    city: artist?.location?.city || '',
    state: artist?.location?.state || '',
  },
  contact: {
    name: artist?.contact?.name || '',
    email: artist?.contact?.email || '',
    phone: artist?.contact?.phone || '',
  },
  paymentMethod: {
    type: artist?.paymentMethod?.type || '',
    address: artist?.paymentMethod?.address || '',
  },
});

export interface IVenueForm {
  name?: string;
  description?: string;
  urls?: string;
  publiclyHidden?: boolean;
  location?: {
    streetAddress?: string;
    city?: string;
    state?: string;
    timeZone?: string;
  };
}

export const getFormValuesFromVenue = (venue?: Venue): IVenueForm => ({
  name: venue?.name || '',
  description: venue?.description || '',
  urls: venue?.urls || '',
  publiclyHidden: venue?.publiclyHidden || false,
  location: {
    city: venue?.location?.city || '',
    state: venue?.location?.state || '',
    streetAddress: venue?.location?.streetAddress || '',
    timeZone: venue?.location?.timeZone || '',
  },
});
