import {
  connectInfiniteHits,
  InfiniteHitsProvided,
} from 'react-instantsearch-core';

import { Link as RRLink } from 'react-router-dom';

import {
  Text,
  Table,
  Tbody,
  Thead,
  Th,
  Td,
  Tr,
  Link,
  Button,
  Icon,
} from '@chakra-ui/react';
import { HiEyeOff } from 'react-icons/hi';

interface ITableInfiniteHits extends InfiniteHitsProvided {
  urlBase: string;
}

const TableInfiniteHitsBase: React.FC<ITableInfiniteHits> = ({
  urlBase,
  hits,
  hasPrevious,
  hasMore,
  refineNext,
  refinePrevious,
}) => {
  const isArtist = urlBase === 'artists';

  const renderHit = (hit: any) => (
    <Tr key={hit.id}>
      <Td width="60%">
        <Link as={RRLink} to={`/${urlBase}/${hit.id}`}>
          {hit?.name}
        </Link>
        {hit?.publiclyHidden && (
          <Text display="flex" opacity={0.3} fontSize="12" alignItems="center">
            <Icon as={HiEyeOff} size="12" mr={2} />
            Hidden From Search
          </Text>
        )}
      </Td>
      <Td width="20%">
        {hit?.streetAddress ? `${hit?.streetAddress}, ` : ''}
        {hit?.city ? `${hit?.city}, ` : ''}
        {hit?.state}
      </Td>
      {isArtist && (
        <Td width="20%">
          <strong>{hit?.contactName}</strong>
          <br />
          {hit?.contactEmail}
          <br />
          {hit?.contactPhone}
        </Td>
      )}
    </Tr>
  );

  return (
    <>
      {hasPrevious && (
        <Button size={'sm'} onClick={refinePrevious}>
          Page previous
        </Button>
      )}
      <Table>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Location</Th>
            {isArtist && <Th>Contact</Th>}
          </Tr>
        </Thead>
        <Tbody>{hits?.map(renderHit)}</Tbody>
      </Table>
      {hasMore && (
        <Button size={'sm'} onClick={refineNext}>
          Show more
        </Button>
      )}
    </>
  );
};

export const TableInfiniteHits = connectInfiniteHits(TableInfiniteHitsBase);
