import firebase from 'firebase/app';
import {
  FormControl,
  FormHelperText,
  Button,
  Flex,
  useDisclosure,
  Heading,
  VStack,
  HStack,
} from '@chakra-ui/react';

import { useHistory } from 'react-router-dom';
import { EditVenueModal } from '../../../components/EditVenueModal';
import { Page } from '../../../components/Page';
import { useExportUrl } from '../../../hooks/useExportUrl';
import TypesenseInstantsearchAdapter from 'typesense-instantsearch-adapter';
import { typesenseConfig } from '../../../config';
import { InstantSearch } from 'react-instantsearch-core';
import { SearchInput } from '../../../components/SearchInput';
import { TableInfiniteHits } from '../../../components/TableInfiniteHits';

const typesenseInstantsearchAdapter = new TypesenseInstantsearchAdapter({
  server: {
    ...typesenseConfig,
    cacheSearchResultsForSeconds: 2 * 60,
  },
  additionalSearchParameters: {
    queryBy: 'name,city,state',
    numTypos: 0,
  },
});
const searchClient = typesenseInstantsearchAdapter.searchClient;

export const AdminVenueListScreen: React.FC<any> = (): JSX.Element => {
  const venueRef = firebase.firestore().collection('venues').doc();
  const storageRef = firebase.storage().ref(`venues/${venueRef.id}`);
  const exportVenuesUrl = useExportUrl('exportVenues');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();

  const onCloseCreate = async () => {
    onClose();
    const venue = await venueRef.get();

    if (venue.exists) {
      history.push(`/venues/${venue.id}`);
    }
  };

  return (
    <Page title="Venues • Admin">
      <VStack maxWidth="1200px" mx="auto" spacing="2" mt="2">
        <Flex w="100%" p={2} justifyContent={'space-between'}>
          <Heading>Venues</Heading>
          <HStack>
            <Button onClick={onOpen}>Create Venue</Button>
            <Button as="a" href={exportVenuesUrl}>
              Export All
            </Button>
          </HStack>
        </Flex>

        <InstantSearch indexName="venues" searchClient={searchClient}>
          <FormControl p="2">
            <SearchInput placeholder="Search for Venues" />
            <FormHelperText>
              You can search by venue name, address, city, or state.
            </FormHelperText>
          </FormControl>
          <TableInfiniteHits urlBase="venues" />
        </InstantSearch>
      </VStack>
      {isOpen && (
        <EditVenueModal
          header="Create Venue"
          storageRef={storageRef}
          venueRef={venueRef}
          onClose={onCloseCreate}
        />
      )}
    </Page>
  );
};
