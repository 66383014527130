import firebase from 'firebase';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import { Flex, Text } from '@chakra-ui/react';
import { Card } from '../../components/Card';
import { Page } from '../../components/Page';
import { useFanflexUser } from '../../hooks/useFanflexUser';
import { artistURLSnapshot } from '../../lib/artist';

export interface ICampaignParams {
  slug: string;
}

export const CampaignScreen: React.FC = () => {
  const { user } = useFanflexUser();
  const firestore = useMemo(() => firebase.firestore(), []);
  const history = useHistory();
  const { slug } = useParams<ICampaignParams>();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    async function resolveAndRedirect() {
      const querySnap = await firestore
        .collectionGroup('campaigns')
        .where('slug', '==', slug)
        .orderBy('createdAt')
        .get();

      const campaignSnap = querySnap.docs[0];

      const campaignId = campaignSnap?.id;
      const artist = await campaignSnap?.ref.parent.parent?.get();

      if (campaignId && artist) {
        history.replace(`${artistURLSnapshot(artist)}/campaigns/${campaignId}`);
      }

      setLoading(false);
    }

    resolveAndRedirect();
  }, []);

  return (
    <Page hideHeader={!user}>
      <Flex minH="85vh" p={3} justifyContent="center" alignItems="center">
        <Card isLoading={isLoading}>
          {!isLoading && (
            <Text alignSelf="center" pt="10" pb="10">
              We couldn't find this campaign.
            </Text>
          )}
        </Card>
      </Flex>
    </Page>
  );
};
