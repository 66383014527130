import * as React from 'react';
import { useFormikContext } from 'formik';
import { Select } from '@chakra-ui/react';
import { SimpleField } from '../SimpleField';

export const SimpleSelect: React.FC<any> = ({
  children,
  name,
  label,
  helper,
  placeholder = '-',
  ...rest
}) => {
  const { getFieldProps } = useFormikContext<any>();

  return (
    <SimpleField name={name} label={label} helper={helper} {...rest}>
      <Select placeholder={placeholder} {...getFieldProps(name)}>
        {children}
      </Select>
    </SimpleField>
  );
};
