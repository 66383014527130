import firebase from 'firebase/app';
import { useEffect, useMemo, useState } from 'react';
import { DocumentSnapshot } from '@firebase/firestore-types';
import { Artist } from '../types/Artist';

interface IUseFanFlexUser {
  isAdmin?: boolean;
  isArtist?: boolean;
  isLoading: boolean;
  managedArtist?: DocumentSnapshot<Artist>;
  user?: firebase.User | null;
  logoutUser: () => void;
}

async function resolveIsAdmin(user: firebase.User | null) {
  if (!user) {
    return false;
  }

  const { claims } = await user.getIdTokenResult();
  return !!claims.admin;
}

async function resolveManagedArtist(user: firebase.User | null) {
  if (!user) {
    return;
  }

  const userRec = await firebase.firestore().doc(`users/${user.uid}`).get();
  return await userRec?.data()?.managedArtists?.[0]?.get();
}

export function useFanflexUser(): IUseFanFlexUser {
  const auth = useMemo(() => firebase.auth(), []);
  const [user, setUser] = useState<firebase.User | null>();
  const [managedArtist, setManagedArtist] = useState<
    DocumentSnapshot<Artist>
  >();
  const [isAdmin, setIsAdmin] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      setIsLoading(true);

      try {
        setUser(user);
        setIsAdmin(await resolveIsAdmin(user));
        setManagedArtist(await resolveManagedArtist(user));
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    });
  }, [auth]);

  const isArtist = !!managedArtist;

  const logoutUser = () => auth.signOut().then(() => location.reload());

  return {
    // Data
    isAdmin,
    isArtist,
    isLoading,
    managedArtist,
    user,

    // Methods
    logoutUser,
  };
}
