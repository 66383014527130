import * as React from 'react';
import {
  Table,
  Tbody,
  Td,
  Tr,
  Thead,
  Th,
  Button,
  Flex,
  VStack,
  Heading,
} from '@chakra-ui/react';

import { useParams } from 'react-router-dom';

import { usePagination } from 'use-pagination-firestore';
import { format } from 'date-fns';
import FORMATS from '../../lib/dateTimeFormats';
import { Page } from '../../components/Page';
import {
  QueryDocumentSnapshot,
  CollectionReference,
} from '@firebase/firestore-types';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { idAndRefFields } from '../../lib/idAndRefFields';
import { Ticket } from '../../types/Ticket';
import { currencyFormatter } from '../../lib/currencyFormatter';
import { useArtistCampaignSearch } from '../../hooks/useArtistCampaignSearch';

interface ArtistCampaignListRow {
  ticketRef: QueryDocumentSnapshot;
}

const ArtistCampaignTicketListRow: React.FC<ArtistCampaignListRow> = ({
  ticketRef,
}) => {
  const [ticket] = useDocumentDataOnce<Ticket>(ticketRef.ref, idAndRefFields);
  const createdAt = ticket?.createdAt?.toDate();

  if (!ticket) return <></>;

  return (
    <Tr>
      <Td>{ticket.name}</Td>
      <Td>{ticket.email}</Td>
      <Td>
        {ticket.quantity}
        {' / '}
        {ticket.amountTotal && currencyFormatter(ticket.amountTotal)}
      </Td>
      <Td>{createdAt && format(createdAt, FORMATS.DATETIME)}</Td>
    </Tr>
  );
};

interface IArtistCampaignTicketListTable {
  ticketsRef: CollectionReference;
}

export const ArtistCampaignTicketListTable: React.FC<IArtistCampaignTicketListTable> = ({
  ticketsRef,
}) => {
  const {
    docs,
    isStart,
    isEnd,
    getPrev,
    getNext,
    isLoading,
  } = usePagination<Ticket>(ticketsRef.orderBy('createdAt'), { limit: 25 });

  const disablePrev = isStart || docs.length === 0;

  return (
    <>
      <Table>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Email</Th>
            <Th>Quantity</Th>
            <Th>When</Th>
          </Tr>
        </Thead>
        <Tbody>
          {docs.map((ticketRef, i) => (
            <ArtistCampaignTicketListRow ticketRef={ticketRef} key={i} />
          ))}
        </Tbody>
      </Table>
      <Flex width="100%" p={2} justifyContent="space-between">
        <Button
          size="sm"
          disabled={disablePrev}
          isLoading={isLoading}
          onClick={getPrev}
        >
          Previous Page
        </Button>
        <Button
          size="sm"
          disabled={isEnd}
          isLoading={isLoading}
          onClick={getNext}
        >
          Next Page
        </Button>
      </Flex>
    </>
  );
};

interface IArtistCampaignListScreen {
  campaignId: string;
  artistId: string;
}

export const ArtistCampaignTicketListScreen: React.FC = () => {
  const { artistId, campaignId } = useParams<IArtistCampaignListScreen>();
  const { campaignSnap } = useArtistCampaignSearch(artistId, campaignId);

  const title = campaignSnap?.get('title');
  const ticketsRef = campaignSnap?.ref.collection('tickets');

  return (
    <>
      <Page title="Tickets">
        <VStack marginX="auto" maxW="1000" alignSelf="center" pt="10">
          {title && (
            <Heading size="md">
              Ticket sales for <i>{title}</i>
            </Heading>
          )}
          {ticketsRef && (
            <ArtistCampaignTicketListTable ticketsRef={ticketsRef} />
          )}
        </VStack>
      </Page>
    </>
  );
};
