import firebase from 'firebase/app';
import { FormikProvider, useFormik } from 'formik';
import { FormEvent } from 'react';
import { Button, Heading, useToast, VStack } from '@chakra-ui/react';
import * as Yup from 'yup';

import { Page } from '../../components/Page';
import { Card } from '../../components/Card';
import { SimpleInput } from '../../components/SimpleInput';

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
});

export const PasswordResetScreen: React.FC = () => {
  const toast = useToast();

  const onSubmit = async ({ email }: any) => {
    try {
      await firebase.auth().sendPasswordResetEmail(email);
      toast({
        status: 'success',
        title: 'Check your email',
        description: 'We just sent an email with password reset instructions.',
      });
    } catch (error: any) {
      toast({ status: 'error', title: 'Uh Oh!', description: error?.message });
    }
  };

  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema,
    onSubmit,
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    formik.submitForm();
  };

  return (
    <Page title="Password Reset">
      <VStack minH="85vh" flex="1" alignItems="center" justifyContent="center">
        <Card maxW="100%" w="400px">
          <FormikProvider value={formik}>
            <Heading size="lg">Forgot Your Password?</Heading>
            <form onSubmit={handleSubmit}>
              <VStack spacing={6}>
                <SimpleInput label="Email Address" name="email" size="lg" />
                <Button
                  width="100%"
                  variant="primary"
                  size="lg"
                  onClick={formik.submitForm}
                  disabled={!formik.isValid}
                  isLoading={formik.isSubmitting}
                >
                  Reset Password
                </Button>
              </VStack>
            </form>
          </FormikProvider>
        </Card>
      </VStack>
    </Page>
  );
};
