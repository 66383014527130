import * as React from 'react';
import { useFormikContext } from 'formik';
import { Input } from '@chakra-ui/react';
import { SimpleField } from '../SimpleField';

export const SimpleInput: React.FC<any> = ({
  name,
  label,
  placeholder,
  helper,
  ...rest
}) => {
  const { getFieldProps } = useFormikContext<any>();

  return (
    <SimpleField name={name} label={label} helper={helper} {...rest}>
      <Input
        type="text"
        placeholder={placeholder}
        {...rest}
        {...getFieldProps(name)}
      />
    </SimpleField>
  );
};
