import firebase from 'firebase/app';
import { useState, useEffect, useMemo } from 'react';
import { DocumentSnapshot } from '@firebase/firestore-types';

import { Artist } from '../types/Artist';

interface IUseArtistSearch {
  artistSnap?: DocumentSnapshot<Artist>;
  isLoading: boolean;
}

export function useArtistSearch(artistId: string): IUseArtistSearch {
  const firestore = useMemo(() => firebase.firestore(), []);

  const [isLoading, setLoading] = useState<boolean>(true);
  const [artistSnap, setArtistSnap] = useState<DocumentSnapshot<Artist>>();

  async function artistSearch(artistId: string) {
    const bySlug = await firestore
      .collection('artists')
      .where('slug', '==', artistId)
      .limit(1)
      .get();

    const slugPath = bySlug.docs[0] && bySlug.docs[0].ref.path;

    firestore.doc(slugPath || `artists/${artistId}`).onSnapshot((doc) => {
      setArtistSnap(doc as DocumentSnapshot<Artist>);
      setLoading(false);
    });
  }

  useEffect(() => {
    artistSearch(artistId);
  }, [artistId]);

  return { artistSnap, isLoading };
}
