import { Select, VStack, Button } from '@chakra-ui/react';
import { connectMenu } from 'react-instantsearch-core';
import states from 'states-us/dist';

const sortAlphaLabel = (a: any, b: any) => (a?.label > b?.label ? 1 : -1);

export const SelectRefinementBase: React.FC<any> = ({
  items,
  currentRefinement,
  refine,
}) => {
  const cleanItems = items
    ?.map((item: any) => {
      const label = states?.find((s) => s?.abbreviation === item?.label)?.name;

      return { ...item, label };
    })
    .sort(sortAlphaLabel);

  return (
    <VStack flex="1" alignItems="flex-end">
      <Select
        value={currentRefinement || ''}
        onChange={(event) => refine(event.currentTarget.value)}
        size="lg"
        variant="filled"
      >
        <option value="">Select a State</option>
        {cleanItems?.map((item: any) => (
          <option
            key={item.value}
            value={item.isRefined ? currentRefinement : item.value}
          >
            {item?.label}
          </option>
        ))}
      </Select>
      {currentRefinement && (
        <Button variant="link" onClick={() => refine(null)}>
          Clear State
        </Button>
      )}
    </VStack>
  );
};

export const SelectRefinement = connectMenu(SelectRefinementBase);
