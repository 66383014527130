import { useEffect, useState } from 'react';
import firebase from 'firebase/app';

interface Document {
  name: string;
  downloadURL: string;
  ref: firebase.storage.Reference;
}

interface IUseDocumentsByRefRet {
  documents: Document[];
  loading: boolean;
  refresh: () => void;
}

export function useDocumentsByRef(
  documentsRef: firebase.storage.Reference,
): IUseDocumentsByRefRet {
  const [documents, setDocuments] = useState<Document[]>([]);
  const [refresh, setRefresh] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    async function resolveDownloadLinks() {
      setLoading(true);

      const docs = await documentsRef.listAll();
      const resolved = await Promise.all(
        docs.items.map(async (item) => ({
          name: item.name,
          downloadURL: await item.getDownloadURL(),
          ref: item,
        })),
      );

      setDocuments(resolved);
      setLoading(false);
    }

    resolveDownloadLinks();
  }, [refresh]);

  return { documents, loading, refresh: () => setRefresh(Date.now()) };
}
