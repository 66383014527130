import 'react-datepicker/dist/react-datepicker.css';

import firebase from 'firebase/app';
import {
  Alert,
  Button,
  Heading,
  HStack,
  Input,
  List,
  ListItem,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { HiPlus, HiTrash } from 'react-icons/hi';

import DatePicker from 'react-datepicker';

import { Venue } from '../../types/Venue';
import { useCollection, useDocumentData } from 'react-firebase-hooks/firestore';
import { Slot } from '../../types/Slot';
import { zonedTimeToUtc } from 'date-fns-tz';
import { useState, useEffect } from 'react';
import { dateTimeFormatterInZone } from '../../lib/dateFormatter';

interface Props {
  venueRef: firebase.firestore.DocumentReference<Venue>;
}

const nowish = firebase.firestore.Timestamp.now();

const noonTomorrow = (() => {
  const s = new Date();
  s.setHours(12);
  s.setMinutes(0);
  s.setSeconds(0);
  s.setMilliseconds(0);
  s.setDate(s.getDate() + 1);
  return s;
})();

export const UpcomingSlots: React.FC<Props> = ({ venueRef }) => {
  const [venue] = useDocumentData<Venue>(venueRef);
  const slotCollection = venueRef.collection('slots');
  const [upcomingSlots, loading] = useCollection<Slot>(
    slotCollection.where('when', '>', nowish).orderBy('when'),
  );

  const timeZone = venue?.location?.timeZone;
  const [when, setWhen] = useState<Date | null>(noonTomorrow);
  const [error, setError] = useState<string>();

  useEffect(() => setError(undefined), [when]);

  const addSlot = async () => {
    if (!(when && timeZone)) {
      return;
    }

    const zonedTimestamp = firebase.firestore.Timestamp.fromDate(
      zonedTimeToUtc(when, timeZone),
    );

    const existingDate = await slotCollection
      .where('when', '==', zonedTimestamp)
      .get();

    if (existingDate.size > 0) {
      setError('Slot at that time already exists');
    } else {
      slotCollection.add({
        when: zonedTimestamp,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    }
  };

  const removeSlot = (
    slotRef: firebase.firestore.QueryDocumentSnapshot<Slot>,
  ) => {
    slotRef.ref.delete();
  };

  if (loading) {
    return null;
  }

  return (
    <>
      <HStack alignItems="flex-start" justifyContent="space-between">
        <Heading size="md">Upcoming Slots</Heading>
      </HStack>

      {error && <Alert status="error">{error}</Alert>}

      {!timeZone ? (
        <Alert status="error">
          Assign a Time Zone to the Venue to manage slots.
        </Alert>
      ) : (
        <>
          <HStack>
            <DatePicker
              selected={when}
              showTimeSelect
              dateFormat="Pp"
              onChange={(date) => setWhen(Array.isArray(date) ? date[0] : date)}
              customInput={<Input />}
            />
            <Button
              size="sm"
              leftIcon={<HiPlus />}
              colorScheme="teal"
              variant="solid"
              onClick={() => addSlot()}
            >
              Add Slot
            </Button>
          </HStack>

          <List spacing={3}>
            {upcomingSlots?.docs.map((slotRef) => {
              const slot = slotRef.data();
              const label = dateTimeFormatterInZone(timeZone).format(
                slot.when.toDate(),
              );

              return (
                <ListItem key={slotRef.id}>
                  <HStack>
                    <Text>{label}</Text>
                    <Spacer />
                    <Button
                      size="xs"
                      leftIcon={<HiTrash />}
                      colorScheme="red"
                      variant="outline"
                      onClick={() => removeSlot(slotRef)}
                    >
                      Remove
                    </Button>
                  </HStack>
                </ListItem>
              );
            })}
          </List>
        </>
      )}
    </>
  );
};
