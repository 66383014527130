import { useRef, useState } from 'react';
import firebase from 'firebase/app';
import { AddIcon } from '@chakra-ui/icons';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  IconButton,
  Progress,
  Text,
  useToast,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { v4 as uuidv4 } from 'uuid';

interface IProfileImagesEdit {
  documentRef: firebase.firestore.DocumentReference;
  storageRef: firebase.storage.Reference;
}

export const CAROUSEL_KEY = 'carouselImages';

export const ProfileImagesEdit: React.FC<IProfileImagesEdit> = ({
  documentRef,
  storageRef,
}) => {
  const toast = useToast();
  const [document] = useDocumentData(documentRef);
  const inputRef = useRef<HTMLInputElement>(null);
  const [progress, setProgress] = useState<number>(-1);

  const images = (document?.[CAROUSEL_KEY] || []) as string[];
  const carouselStorageRef = storageRef.child(CAROUSEL_KEY);

  const setCarouselImages = (carouselImages: string[]) =>
    documentRef.set({ [CAROUSEL_KEY]: carouselImages }, { merge: true });

  const uploadFile = (file: File) => {
    const fileName = uuidv4();
    const fileRef = carouselStorageRef.child(fileName);
    const task = fileRef.put(file);

    task.on(
      'state_changed',
      (snapshot) => {
        const p = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(p);
      },
      (error) => {
        console.error(error.message);
        toast({
          status: 'error',
          title: 'Error uploading file!',
          description: error.message,
        });
        setProgress(-1);
      },
      () => {
        setProgress(-1);

        fileRef
          .getDownloadURL()
          .then((url) => setCarouselImages([...images, url]));
      },
    );
  };

  const remove = (url: string) =>
    setCarouselImages(images.filter((u) => u !== url));

  const [imageToRemove, setImageToRemove] = useState<string>();
  const onCloseAlert = () => setImageToRemove(undefined);
  const onDeleteImage = () => {
    if (!imageToRemove) {
      return;
    }

    remove(imageToRemove);
    onCloseAlert();
  };
  const cancelRef = useRef(null);

  return (
    <>
      <input
        style={{ display: 'none' }}
        type="file"
        onChange={(e) => {
          const file = e.target.files?.[0];

          if (file) {
            uploadFile(file);
          }
        }}
        ref={inputRef}
      />
      <VStack alignItems="start" w="100%">
        <Text>Profile Images</Text>
        {progress > -1 && (
          <Progress w="100%" size="xs" colorScheme="green" value={progress} />
        )}
        <Wrap>
          {images.map((image, index) => (
            <WrapItem>
              <Button
                key={index}
                title="Delete image"
                style={{
                  height: '76px',
                  width: '76px',
                  backgroundImage: `url(${image})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                onClick={() => setImageToRemove(image)}
              ></Button>
            </WrapItem>
          ))}
          <WrapItem height="76px">
            <IconButton
              disabled={progress > -1}
              alignSelf="center"
              title="Add Image"
              colorScheme="green"
              aria-label="Add Image"
              icon={<AddIcon />}
              onClick={() => inputRef.current?.click()}
            >
              Add
            </IconButton>
          </WrapItem>
        </Wrap>
      </VStack>

      <AlertDialog
        isOpen={imageToRemove !== undefined}
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete image
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseAlert}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={onDeleteImage} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
