import { Heading } from '@chakra-ui/layout';
import { Button, HStack, useToast, VStack, Link } from '@chakra-ui/react';

import firebase from 'firebase';
import * as Yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { FormEvent } from 'react';

import { Card } from '../../components/Card';
import { Page } from '../../components/Page';
import { SimpleCheckbox } from '../../components/SimpleCheckbox';
import { SimpleInput } from '../../components/SimpleInput';
import { useHistory } from 'react-router-dom';
import { ExternalLinkIcon } from '@chakra-ui/icons';

const initialValues = {
  email: '',
  password: '',
  passwordConfirmation: '',
  tosAccepted: false,
};

export const SignUpScreen: React.FC = () => {
  const toast = useToast();
  const history = useHistory();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required('Email is required'),
    password: Yup.string().required('Password is required').min(8),
    passwordConfirmation: Yup.string()
      .required('Password confirmation is required')
      .oneOf([Yup.ref('password')], 'Passwords must match'),
    tosAccepted: Yup.bool().oneOf(
      [true],
      'You must accept the terms of service',
    ),
  });

  const handleError = (description: string) =>
    toast({ status: 'error', title: 'Uh oh!', description });

  const createUser = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<firebase.User> => {
    await firebase.auth().createUserWithEmailAndPassword(email, password);
    const user = firebase.auth().currentUser;

    if (!user) {
      throw Error("There isn't an authenticated user");
    }

    await user.sendEmailVerification();
    return user;
  };

  const onSubmit = async (values: any) => {
    try {
      const { email, password } = values;

      await createUser({ email, password });
      history.push('/artists/new');
    } catch ({ message }) {
      handleError(message);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    formik.submitForm();
  };

  return (
    <Page title="Create an artist account">
      <VStack minH="85vh" flex="1" alignItems="center" pt="20">
        <Card maxW="100%" w="400px">
          <FormikProvider value={formik}>
            <Heading size="lg">Create an artist account</Heading>
            <form onSubmit={handleSubmit}>
              <VStack spacing={6}>
                <SimpleInput label="Email" name="email" size="lg" />
                <SimpleInput
                  label="Password"
                  name="password"
                  type="password"
                  size="lg"
                />
                <SimpleInput
                  label="Password confirmation"
                  name="passwordConfirmation"
                  type="password"
                  size="lg"
                />
                <HStack alignSelf="start">
                  <SimpleCheckbox
                    label="Accept our Terms of Service"
                    name="tosAccepted"
                  />
                  <Link
                    alignSelf="start"
                    target="_blank"
                    href="https://fanflex.com/legal"
                  >
                    <ExternalLinkIcon />
                  </Link>
                </HStack>
                <Button
                  width="100%"
                  variant="primary"
                  size="lg"
                  onClick={formik.submitForm}
                  disabled={!formik.isValid}
                  isLoading={formik.isSubmitting}
                >
                  Continue
                </Button>
              </VStack>
            </form>
          </FormikProvider>
        </Card>
      </VStack>
    </Page>
  );
};
