import { Input } from '@chakra-ui/input';
import { connectSearchBox, SearchBoxProvided } from 'react-instantsearch-core';

interface ISearchInput extends SearchBoxProvided {
  placeholder?: string;
}

const SearchInputBase: React.FC<ISearchInput> = ({
  placeholder,
  currentRefinement,
  refine,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    refine(event.currentTarget.value);

  return (
    <Input
      type="search"
      variant="filled"
      size="lg"
      value={currentRefinement}
      placeholder={placeholder}
      onChange={handleChange}
      flex="2"
    />
  );
};

export const SearchInput = connectSearchBox(SearchInputBase);
