import * as React from 'react';
import firebase from 'firebase/app';

import { Link } from 'react-router-dom';
import { TimeIcon } from '@chakra-ui/icons';
import {
  Heading,
  List,
  ListItem,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/layout';
import { Button, Tag, TagLabel, TagLeftIcon } from '@chakra-ui/react';
import { HiOutlineTicket } from 'react-icons/hi';
import { Card } from '../Card';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { DocumentReference } from '@firebase/firestore-types';
import { useDocumentsRelatedToCampaign } from '../../hooks/useDocumentsRelatedToCampaign';
import { campaignURL } from '../../lib/campaign';
import FORMATS from '../../lib/dateTimeFormats';
import { Campaign } from '../../types/Campaign';
import { Artist } from '../../types/Artist';
import { Data } from 'react-firebase-hooks/firestore/dist/firestore/types';
import { idAndRefFields } from '../../lib/idAndRefFields';
import { useDateFormatterInVenueZone } from '../../hooks/useDateFormatterInVenueZone';

const nowish = firebase.firestore.Timestamp.now();

const UpcomingShowsCard: React.FC<{
  campaign: Data<Campaign, 'id', 'ref'>;
}> = ({ campaign }) => {
  const { artist, slot, venue, isLoading } = useDocumentsRelatedToCampaign(
    campaign,
  );

  const df = useDateFormatterInVenueZone(venue);

  return (
    <ListItem>
      <Card isLoading={isLoading} maxWidth="400" width="auto" p={4}>
        <VStack spacing={3} alignItems="flex-start">
          <Heading size="md">{campaign.title}</Heading>

          <Stack
            direction={{ base: 'column', lg: 'row' }}
            alignItems="flex-start"
          >
            {slot && (
              <Tag variant="subtle">
                <TagLeftIcon as={TimeIcon} />
                <TagLabel>{df(FORMATS.DATETIME, slot.when.toDate())}</TagLabel>
              </Tag>
            )}
            <Tag variant="subtle">
              <TagLeftIcon as={HiOutlineTicket} />
              <TagLabel>{`$${campaign.price}`} USD</TagLabel>
            </Tag>
          </Stack>
          <Text>{campaign.description}</Text>

          {artist && (
            <Button
              alignSelf="center"
              as={Link}
              to={campaignURL(campaign, artist)}
              size="md"
              variant="primary"
            >
              Buy Ticket
            </Button>
          )}
        </VStack>
      </Card>
    </ListItem>
  );
};

export const UpcomingShows: React.FC<{
  artistRef: DocumentReference<Artist>;
}> = ({ artistRef }) => {
  const [campaigns] = useCollectionDataOnce<Campaign, 'id', 'ref'>(
    artistRef
      .collection('campaigns')
      .where('_when', '>', nowish)
      .orderBy('_when') as firebase.firestore.Query<Campaign>,
    idAndRefFields,
  );

  return (
    <List spacing={3}>
      {campaigns?.map((campaign, id) => (
        <UpcomingShowsCard campaign={campaign} key={id} />
      ))}
    </List>
  );
};
