export const SOCIAL_URLS = {
  FB: /https:\/\/(www.|)facebook.com\//,
  IG: /https:\/\/(www.|)instagram.com\//,
  TW: /https:\/\/(www.|)twitter.com\//,
};

const splitUrls = (urls: string | undefined) => urls?.split(/\n/) || [];

export const findNotSocialUrl = (
  urls: string | undefined,
): string | undefined =>
  splitUrls(urls).find(
    (v) =>
      /https:\/\//.test(v) &&
      Object.values(SOCIAL_URLS).every((t) => !t.test(v)),
  );

export const findMatch = (prefix: RegExp) => (
  urls: string | undefined,
): string | undefined => splitUrls(urls).find((v) => prefix?.test(v));

export const findFB = findMatch(SOCIAL_URLS.FB);
export const findIG = findMatch(SOCIAL_URLS.IG);
export const findTW = findMatch(SOCIAL_URLS.TW);
