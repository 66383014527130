import { formatInTimeZone } from 'date-fns-tz';
import { Venue } from '../types/Venue';

export const useDateFormatterInVenueZone = (venue: Venue | undefined) => (
  fmt: string,
  date: Date,
): string => {
  const tz = venue?.location?.timeZone || 'America/Los_Angeles';

  return formatInTimeZone(date, tz, fmt);
};
