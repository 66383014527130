import { useHistory } from 'react-router';
import { Location, LocationDescriptor } from 'history';

interface IUseRouteHelp {
  isEdit: boolean;
  pushWithoutEdit: () => void;
  replaceWithoutEdit: () => void;
  toEdit: <T>(location: Location<T>) => LocationDescriptor<T>;
}

export function useRouteHelp(): IUseRouteHelp {
  const history = useHistory();
  const isEdit = location?.pathname?.includes('/edit');

  const pathnameWithoutEdit = () => location?.pathname.split('/edit')?.[0];

  const navigateAwayFromEdit = (type: 'PUSH' | 'REPLACE') => {
    if (!isEdit) {
      return;
    }

    const nextPathname = pathnameWithoutEdit();

    if (!nextPathname) {
      return;
    }

    type === 'REPLACE'
      ? history.replace(nextPathname)
      : history.push(nextPathname);
  };

  /**
   * @description if the current route has /edit, it pushes the same pathname, but without /edit
   * @returns void
   */
  const pushWithoutEdit = () => navigateAwayFromEdit('PUSH');

  /**
   * @description if the current route has /edit, it replaces the same pathname, but without /edit
   * @returns void
   */
  const replaceWithoutEdit = () => navigateAwayFromEdit('REPLACE');

  /**
   * @description simple way to push to /edit
   * @returns LocationDescriptor for use in a React-Router Link to={}
   */
  const toEdit = <T>({
    pathname,
    ...rest
  }: Location<T>): LocationDescriptor<T> => ({
    ...rest,
    pathname: `${pathname}/edit`,
  });

  return {
    isEdit,

    // Methods
    toEdit,
    replaceWithoutEdit,
    pushWithoutEdit,
  };
}
