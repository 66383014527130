import * as React from 'react';
import { useFormikContext } from 'formik';
import { SimpleField } from '../SimpleField';
import GENRES from '../../lib/genres.json';

import { MultiSelect } from './MultiSelect';
import { useState, useEffect } from 'react';
import { OptionsType, ActionMeta } from 'react-select';

type Option = {
  label: string;
  value: string;
};

export const GenreSelect: React.FC<any> = ({
  name,
  label,
  helper,
  placeholder = '-',
  ...rest
}) => {
  const [selectedOptions, setSelectedOptions] = useState<OptionsType<Option>>();

  const { setFieldValue, initialValues } = useFormikContext<any>();
  const defaultValue = initialValues[name].map((v: string) => ({
    label: v,
    value: v,
  }));

  const onSelectChange = (
    values: OptionsType<Option>,
    { action }: ActionMeta<Option>,
  ) => {
    if (action === 'select-option') {
      if (values.length < 4) {
        setSelectedOptions(values);
      }
    }

    if (action === 'remove-value') {
      setSelectedOptions(values);
    }
  };

  useEffect(() => {
    if (selectedOptions) {
      setFieldValue(
        name,
        selectedOptions.map((v) => v.value),
      );
    }
  }, [selectedOptions]);

  return (
    <SimpleField name={name} label={label} helper={helper} {...rest}>
      <MultiSelect
        value={selectedOptions}
        defaultValue={defaultValue}
        styles={{
          container: (provided) => ({
            ...provided,
            width: '100%',
          }),
          multiValue: (provided) => ({
            ...provided,
            backgroundColor: 'transparent',
            borderColor: 'var(--chakra-colors-whiteAlpha-300)',
            borderWidth: '1px',
            color: 'var(--chakra-colors-whiteAlpha-900)',
          }),
          multiValueLabel: (provided) => ({
            ...provided,
            color: 'var(--chakra-colors-whiteAlpha-900)',
          }),
        }}
        placeholder={placeholder}
        isMulti
        isClearable={false}
        options={GENRES.map((g) => ({ label: g, value: g }))}
        closeMenuOnSelect={false}
        size="sm"
        onChange={onSelectChange}
        name={name}
      />
    </SimpleField>
  );
};
