import { Box, Heading, ListItem, OrderedList } from '@chakra-ui/layout';

export const WantToBookAShowMessage: React.FC = () => (
  <Box p="3">
    <Heading size="md" mb="2">
      Want to book a show?
    </Heading>
    <OrderedList>
      <ListItem>Click “Browse Venues” in top left</ListItem>
      <ListItem>Find a Venue that works for you</ListItem>
      <ListItem>
        Request to book on an Availability Date that works for you
      </ListItem>
    </OrderedList>
  </Box>
);
