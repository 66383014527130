import * as React from 'react';
import firebase from 'firebase/app';

import { Link as RRLink, useParams, useLocation } from 'react-router-dom';
import {
  VStack,
  HStack,
  Heading,
  Text,
  Stack,
  Spacer,
  Divider,
  Button,
} from '@chakra-ui/react';

import { useRouteHelp } from '../../hooks/useRouteHelp';

import { Card } from '../../components/Card';
import { DetailPage } from '../../components/DetailPage';
import { DocumentsSection } from '../../components/DocumentsSection';
import { EditArtistModal } from '../../components/EditArtistModal';
import { UpcomingShows } from '../../components/UpcomingShows';
import { ParagraphedText } from '../../components/ParagraphedText';
import { WantToBookAShowMessage } from '../../components/WantToBookAShowMessage';
import { Artist } from '../../types/Artist';
import { useAuthorization } from '../../hooks/useAuthorization';
import { useArtistSearch } from '../../hooks/useArtistSearch';
import { useFanflexUser } from '../../hooks/useFanflexUser';

interface Params {
  artistId: string;
}

export const ArtistScreen: React.FC<any> = () => {
  const { artistId } = useParams<Params>();
  const { artistSnap, isLoading } = useArtistSearch(artistId);
  const { isAdmin, managedArtist } = useFanflexUser();

  const artist = artistSnap?.data() as Artist;

  const location = useLocation();

  const { canUpdate } = useAuthorization();

  const storageRef = firebase.storage().ref(`artists/${artistSnap?.id}`);
  const documentsRef = storageRef.child('documents');

  const { toEdit, isEdit, replaceWithoutEdit } = useRouteHelp();

  const hasPaymentMethod =
    artist?.paymentMethod?.type || artist?.paymentMethod?.address;

  const hasContactMethod =
    artist?.contact?.name || artist?.contact?.email || artist?.contact?.phone;

  const canEdit = canUpdate(artist);

  if (!isLoading && !canEdit && isEdit) {
    replaceWithoutEdit();
  }

  if (!artist && !isLoading) {
    return <Text>Artist not found</Text>;
  }

  if (!artistSnap || !artist) {
    return null;
  }

  return (
    <>
      {isEdit && canEdit && (
        <EditArtistModal
          artist={artist}
          artistRef={artistSnap.ref}
          onClose={replaceWithoutEdit}
          storageRef={storageRef}
        />
      )}
      <DetailPage
        heading={artist.name}
        subheading={
          Array.isArray(artist.genres) ? artist.genres.join(', ') : ''
        }
        documentRef={artistSnap.ref}
        avatarUri={artist.avatarUri}
        urls={artist.urls}
      >
        <Stack
          spacing="8"
          pt="10"
          pb="10"
          maxW="1000"
          width="100%"
          alignItems="flex-start"
          direction={{ base: 'column', lg: 'row' }}
        >
          <VStack alignItems="flex-start" flex={3} spacing={3}>
            {artist?.description && (
              <Card width="100%">
                <ParagraphedText>{artist?.description}</ParagraphedText>
              </Card>
            )}
            {canEdit && (
              <Card width="100%">
                <HStack
                  alignItems="flex-start"
                  justifyContent="space-between"
                  flexDirection={{ base: 'column', lg: 'row' }}
                >
                  <Heading size="lg">Manage</Heading>
                  <Stack
                    width="100%"
                    direction={{ base: 'column', lg: 'row' }}
                    pt={{ base: 4, lg: 0 }}
                    pl={{ base: 0, lg: 10 }}
                    style={{ marginInlineStart: 0 }}
                  >
                    <Button
                      as={RRLink}
                      variant="primary"
                      size="md"
                      to={toEdit}
                      replace
                      isFullWidth
                    >
                      Edit Profile
                    </Button>
                    <Button
                      as={RRLink}
                      size="md"
                      to={`${location.pathname}/campaigns`}
                      isFullWidth
                    >
                      View Campaigns
                    </Button>
                  </Stack>
                </HStack>
                <Spacer height="6" />
                <DocumentsSection
                  documentsRef={documentsRef}
                  title="Tech Specs & Stage Plots"
                />
                {hasContactMethod && hasPaymentMethod && (
                  <>
                    <Spacer height="6" />
                    <VStack alignItems="flex-start" pt="5">
                      <Heading size="md">Account</Heading>
                      {hasContactMethod && (
                        <HStack width="100%" alignItems="flex-start" pt="3">
                          <Heading size="sm">Contact Details</Heading>
                          <Spacer />
                          <VStack alignItems="flex-end">
                            <Text>{artist?.contact?.name}</Text>
                            <Text>{artist?.contact?.email}</Text>
                            <Text>{artist?.contact?.phone}</Text>
                          </VStack>
                        </HStack>
                      )}
                      <Divider />
                      {hasPaymentMethod && (
                        <HStack width="100%">
                          <Heading size="sm">Payout Method</Heading>
                          <Spacer />
                          <Text>
                            {artist?.paymentMethod?.type} —{' '}
                            {artist?.paymentMethod?.address}
                          </Text>
                        </HStack>
                      )}
                    </VStack>
                  </>
                )}
              </Card>
            )}
          </VStack>
          <VStack alignItems="flex-start" flex="2">
            <Heading size="md">Upcoming Shows</Heading>
            <UpcomingShows artistRef={artistSnap.ref} />
            {(isAdmin || managedArtist?.id == artistSnap.id) && (
              <WantToBookAShowMessage />
            )}
          </VStack>
        </Stack>
      </DetailPage>
    </>
  );
};
