import firebase from 'firebase';
import { FormikProvider, useFormik } from 'formik';
import { FormEvent, useEffect } from 'react';
import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { Link as RRLink } from 'react-router-dom';
import {
  Button,
  Center,
  Heading,
  Stack,
  Link,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';

import { Page } from '../../components/Page';
import { Card } from '../../components/Card';
import { SimpleInput } from '../../components/SimpleInput';

const initialValues = {
  email: '',
  password: '',
};

export const LoginForm: React.FC = () => {
  const toast = useToast();
  const [signInWithEmailAndPassword, , , error] = useSignInWithEmailAndPassword(
    firebase.auth(),
  );

  const onSubmit = ({ email, password }: any) =>
    signInWithEmailAndPassword(email, password);

  const formik = useFormik({
    initialValues,
    onSubmit,
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    formik.submitForm();
  };

  const loginErrorToastId = 'login-error-toast';

  useEffect(() => {
    if (!error) {
      return;
    }

    if (!toast.isActive(loginErrorToastId)) {
      toast({
        id: loginErrorToastId,
        status: 'error',
        title: error.message,
      });
    } else {
      toast.update(loginErrorToastId, {
        status: 'error',
        title: error.message,
      });
    }
  }, [error]);

  return (
    <FormikProvider value={formik}>
      <Center>
        <Heading paddingBottom="4" alignContent="center" size="lg">
          Log in to FanFlex
        </Heading>
      </Center>
      <form onSubmit={handleSubmit}>
        <VStack spacing={6}>
          <SimpleInput label="Email address" name="email" size="lg" />
          <SimpleInput
            label="Password"
            name="password"
            type="password"
            size="lg"
          />
          <Button
            width="100%"
            variant="primary"
            size="lg"
            onClick={formik.submitForm}
            disabled={!formik.isValid}
            isLoading={formik.isSubmitting}
            type="submit"
          >
            Log in
          </Button>
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            width="100%"
            spacing="4"
            justifyContent="center"
          >
            <Button to="signup" as={RRLink} variant="outline" size="sm">
              Create an artist account
            </Button>
            <Button to="password-reset" as={RRLink} variant="outline" size="sm">
              Forgot your password?
            </Button>
          </Stack>
          <Text>
            <b>Not an artist?</b>{' '}
            <Link href="https://fanflex.com/contact/">
              <u>Contact us</u>
            </Link>{' '}
            to sign up your venue or to find tickets to see your favorite artist
            perform.
          </Text>
        </VStack>
      </form>
    </FormikProvider>
  );
};

export const LoginScreen: React.FC = () => {
  return (
    <Page title="Log in to FanFlex" hideLoginButton>
      <VStack minH="85vh" flex="1" alignItems="center" justifyContent="center">
        <Card maxW="100%" w="400px">
          <LoginForm />
        </Card>
      </VStack>
    </Page>
  );
};
