import {
  Avatar,
  HStack,
  VStack,
  Heading,
  Text,
  Spacer,
} from '@chakra-ui/react';
import * as React from 'react';
import firebase from 'firebase/app';
import { Page } from '../Page';
import { SocialButton } from '../SocialButton';
import { ProfileCarousel } from '../ProfileCarousel';
import { Artist } from '../../types/Artist';
import { Venue } from '../../types/Venue';

interface IDetailPageProps {
  avatar?: string;
  title?: string;
  heading: string;
  subheading?: string;
  description?: string;
  avatarUri?: string;
  images?: string[];
  urls?: string;
  children?: React.ReactNode;
  documentRef:
    | firebase.firestore.DocumentReference<Venue>
    | firebase.firestore.DocumentReference<Artist>;
}

export const DetailPage = ({
  title,
  avatarUri,
  children,
  documentRef,
  heading,
  subheading,
  urls,
}: IDetailPageProps): JSX.Element => {
  const pageTitle = heading || title || '';

  return (
    <Page title={pageTitle}>
      <VStack>
        <ProfileCarousel documentRef={documentRef} />
        <VStack maxW="1000" width="100%" alignSelf="center" p={5} pt={10}>
          <HStack
            maxW="1000"
            width="100%"
            flexDirection={{ base: 'column', lg: 'row' }}
            alignItems={{ base: 'flex-start', lg: 'center' }}
          >
            {avatarUri && (
              <Avatar size="2xl" mr="6" name={heading} src={avatarUri} />
            )}
            <VStack alignItems="flex-start">
              <Heading as="h1" size="xl" isTruncated>
                {heading}
              </Heading>
              {subheading && (
                <Text fontSize="xl" style={{ whiteSpace: 'pre-line' }}>
                  {subheading}
                </Text>
              )}
            </VStack>
            <Spacer />
            <HStack>
              <SocialButton type="FB" urls={urls} />
              <SocialButton type="TW" urls={urls} />
              <SocialButton type="IG" urls={urls} />
              <SocialButton urls={urls} />
            </HStack>
          </HStack>
          {children}
        </VStack>
      </VStack>
    </Page>
  );
};
