import React from 'react';
import { SimpleGrid, VStack, Text, Stack } from '@chakra-ui/react';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import { InfiniteHitsProvided } from 'react-instantsearch-core';
import InfiniteScroll from 'react-infinite-scroll-component';

import { VenueCard } from '../VenueCard';
import { SearchInput } from '../SearchInput';
import { SelectRefinement } from '../SelectRefinement';

const InfiniteHits: React.FC<InfiniteHitsProvided> = ({
  hits,
  hasMore,
  refineNext,
}) => {
  const renderVenue = ({ id, name, city, state, carouselImages }: any) => (
    <VenueCard
      key={id}
      id={id}
      name={name}
      city={city}
      state={state}
      coverImage={carouselImages?.[0]}
    />
  );

  return (
    <VStack flex={1} alignItems="initial" position="relative">
      <Stack
        position="absolute"
        top={0}
        left={0}
        right={0}
        py={4}
        pl={10}
        pr={14}
        zIndex={10}
        bg="gray.800"
        direction={{ base: 'column', lg: 'row' }}
        width="100%"
      >
        <SearchInput placeholder="Search for Venue by Name or City." />
        <SelectRefinement attribute="state" limit={60} />
      </Stack>
      <InfiniteScroll
        height="92vh"
        style={{ paddingTop: 50 }}
        dataLength={hits.length}
        next={refineNext}
        hasMore={hasMore}
        loader={
          <VStack spacing={4} padding={12}>
            <Text>👀</Text>
          </VStack>
        }
        endMessage={
          <VStack spacing={4} padding={12}>
            <Text>🎉</Text>
            <Text>You have seen it all!</Text>
          </VStack>
        }
      >
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          spacing={{ base: 4, lg: 6, '2xl': 10 }}
          padding={{ base: 4, lg: 6, '2xl': 10 }}
        >
          {hits?.map(renderVenue)}
        </SimpleGrid>
      </InfiniteScroll>
    </VStack>
  );
};

export const VenueInfiniteHits = connectInfiniteHits(InfiniteHits);
