import { extendTheme } from '@chakra-ui/react';
import { loadStripe } from '@stripe/stripe-js';
import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/analytics';
import 'firebase/auth';

function getConfig() {
  if (!process.env.REACT_APP_FIREBASE_PROJECT) {
    throw new Error(
      'REACT_APP_FIREBASE_PROJECT required to initialize application',
    );
  }

  try {
    // eslint-disable-next-line
    return require(`./config/${process.env.REACT_APP_FIREBASE_PROJECT}.json`);
  } catch (e: any) {
    throw new Error(`Failed to load firebase config: ${e.message}`);
  }
}

export const config = getConfig();

firebase.initializeApp(config.firebase);
firebase.analytics();

// firebase.functions().useEmulator('localhost', 5001);
// firebase.firestore().useEmulator('localhost', 8080);

export const stripePromise = loadStripe(config.stripe);
export const typesenseConfig = config.typesense;
export const googleMaps = config.googleMaps;

export const chakraTheme = extendTheme({
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  colors: {
    brand: {
      500: '#EA385D',
    },
  },
  styles: {
    body: {
      backgroundColor: 'gray.900',
    },
  },
  components: {
    Button: {
      variants: {
        primary: {
          backgroundColor: 'brand.500',
        },
      },
    },
  },
});
