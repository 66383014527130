import { Alert, Progress } from '@chakra-ui/react';
import firebase from 'firebase/app';
import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface UploaderInterface {
  storageRef: firebase.storage.Reference;
  onComplete?: (file: firebase.storage.Reference) => void;
  randomFilename?: boolean;
}

export const Uploader: React.FC<UploaderInterface> = ({
  storageRef,
  onComplete,
  randomFilename,
}) => {
  const [file, setFile] = useState<File>();

  const [active, setActive] = useState(false);
  const [progress, setProgress] = useState<number>();
  const [error, setError] = useState<string>();

  const startUpload = () => {
    setActive(true);
    setProgress(undefined);
    setError(undefined);
  };

  const resetProgress = () => {
    setFile(undefined);
    setActive(false);
    setProgress(undefined);
  };

  useEffect(() => {
    if (file) {
      startFileUpload();
    }
  }, [file]);

  const startFileUpload = useCallback(() => {
    if (!file) {
      setError('no file');
      return;
    }

    startUpload();

    const fileName = randomFilename ? uuidv4() : file.name;
    const fileRef = storageRef.child(fileName);
    const task = fileRef.put(file);

    task.on(
      'state_changed',
      (snapshot) => {
        setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      },
      (error) => {
        setError(error.code);
        resetProgress();
      },
      () => {
        setFile(undefined);
        resetProgress();
        onComplete && onComplete(fileRef);
      },
    );
  }, [file]);

  return (
    <>
      {progress ? (
        <Progress value={progress} />
      ) : (
        <input
          disabled={active}
          type="file"
          onChange={(e) => setFile(e.target.files?.[0])}
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
          }}
        />
      )}
      {error && <Alert status="error">{error}</Alert>}
    </>
  );
};
