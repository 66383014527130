import firebase from 'firebase/app';
import { useState, useEffect, useMemo } from 'react';
import { DocumentSnapshot } from '@firebase/firestore-types';

import { Artist } from '../types/Artist';
import { Campaign } from '../types/Campaign';
import { Venue } from '../types/Venue';
import { Slot } from '../types/Slot';

interface IUseArtistCampaignSearch {
  artistSnap?: DocumentSnapshot<Artist>;
  campaignSnap?: DocumentSnapshot<Campaign>;
  slotSnap?: DocumentSnapshot<Slot>;
  venueSnap?: DocumentSnapshot<Venue>;
  isLoading: boolean;
}

export function useArtistCampaignSearch(
  artistId: string,
  campaignId: string,
): IUseArtistCampaignSearch {
  const firestore = useMemo(() => firebase.firestore(), []);

  const [isLoading, setLoading] = useState<boolean>(true);

  const [artistSnap, setArtistSnap] = useState<DocumentSnapshot<Artist>>();
  const [campaignSnap, setCampaignSnap] = useState<
    DocumentSnapshot<Campaign>
  >();
  const [venueSnap, setVenueSnap] = useState<DocumentSnapshot<Venue>>();
  const [slotSnap, setSlotSnap] = useState<DocumentSnapshot<Slot>>();

  async function fetchCampaign(artistId: string, campaignId: string) {
    const campaignSnap = (await firestore
      .doc(`/artists/${artistId}/campaigns/${campaignId}`)
      .get()) as DocumentSnapshot<Campaign>;

    if (!campaignSnap?.exists) {
      return;
    }

    const artistSnap = (await campaignSnap.ref.parent.parent?.get()) as DocumentSnapshot<Artist>;

    const slotSnap = (await campaignSnap
      ?.data()
      ?.slot?.get()) as DocumentSnapshot<Slot>;

    const venueSnap = (await slotSnap?.ref?.parent?.parent?.get()) as DocumentSnapshot<Venue>;

    setArtistSnap(artistSnap);
    setCampaignSnap(campaignSnap);
    setSlotSnap(slotSnap);
    setVenueSnap(venueSnap);

    return true; // return true to stop searching
  }

  async function artistSearch(artistId: string) {
    const artistSearch = await firestore
      .collection('artists')
      .where('slug', '==', artistId)
      .limit(1)
      .get();

    return artistSearch.docs[0];
  }

  async function loadingWrap(cb: () => Promise<any>) {
    setLoading(true);
    await cb();
    setLoading(false);
  }

  useEffect(() => {
    async function resolve() {
      //
      // first pass, try to take artistId as documentId
      if (await fetchCampaign(artistId, campaignId)) {
        return;
      }

      //
      // second pass, try to find artist by slug
      const artistSnap = await artistSearch(artistId);

      if (!artistSnap?.exists) {
        return;
      }

      await fetchCampaign(artistSnap.id, campaignId);
    }

    loadingWrap(resolve);
  }, [artistId, campaignId]);

  return { artistSnap, campaignSnap, slotSnap, venueSnap, isLoading };
}
