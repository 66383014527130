import * as React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { useFanflexUser } from '../../hooks/useFanflexUser';
import { artistURLSnapshot } from '../../lib/artist';
import * as screens from '../../screens';

const AdminRoute: React.FC<any> = (props) => {
  const { isLoading, isAdmin, user } = useFanflexUser();

  if (isLoading) {
    return null;
  }

  if (!user) {
    return <Redirect to="/login" />;
  }

  if (isAdmin) {
    return <Route {...props} />;
  }

  return <Redirect to="/venues" />;
};

const UnauthenticatedRoute: React.FC<any> = ({
  loggedInPath,
  routeToProfile,
  ...props
}) => {
  const { user, managedArtist, isLoading } = useFanflexUser();

  if (isLoading) {
    return null;
  }

  if (managedArtist && routeToProfile) {
    return (
      <Redirect
        to={{
          pathname: artistURLSnapshot(managedArtist),
        }}
      />
    );
  }

  if (user) {
    return <Redirect to={{ pathname: loggedInPath }} />;
  }

  return <Route {...props} />;
};

export const Router: React.FC = () => (
  <BrowserRouter>
    <Switch>
      <AdminRoute
        exact
        path="/venues/:venueId/edit"
        component={screens.VenueScreen}
      />
      <Route exact path="/venues/:venueId" component={screens.VenueScreen} />
      <Route exact path="/venues" component={screens.VenuesScreen} />
      <Route exact path="/artists/new" component={screens.ArtistNewScreen} />
      <Route
        exact
        path="/artists/:artistId/edit"
        component={screens.ArtistScreen}
      />
      <Route
        exact
        path="/artists/:artistId/campaigns"
        component={screens.ArtistCampaignListScreen}
      />
      <Route
        exact
        path="/artists/:artistId/campaigns/:campaignId"
        component={screens.ArtistCampaignScreen}
      />
      <Route
        exact
        path="/artists/:artistId/campaigns/:campaignId/edit"
        component={screens.ArtistCampaignScreen}
      />
      <Route
        exact
        path="/artists/:artistId/campaigns/:campaignId/cancel"
        component={screens.ArtistCampaignScreen}
      />
      <Route
        exact
        path="/artists/:artistId/campaigns/:campaignId/success"
        component={screens.ArtistCampaignScreen}
      />
      <Route
        exact
        path="/artists/:artistId/campaigns/:campaignId/tickets"
        component={screens.ArtistCampaignTicketListScreen}
      />
      <Route exact path="/artists/:artistId" component={screens.ArtistScreen} />
      <Route exact path="/campaigns/:slug" component={screens.CampaignScreen} />

      <UnauthenticatedRoute
        component={screens.LoginScreen}
        exact
        loggedInPath="/admin/venues"
        path="/login"
        routeToProfile
      />
      <UnauthenticatedRoute
        component={screens.SignUpScreen}
        exact
        loggedInPath="/venues"
        path="/signup"
        routeToProfile
      />
      <UnauthenticatedRoute
        component={screens.PasswordResetScreen}
        exact
        loggedInPath="/venues"
        path="/password-reset"
        routeToProfile
      />
      <AdminRoute
        exact
        path="/admin/artists"
        component={screens.AdminArtistListScreen}
      />
      <AdminRoute
        exact
        path="/admin/venues"
        component={screens.AdminVenueListScreen}
      />
      <Redirect exact path="/admin" to="/admin/artists" />
      <Redirect exact path="/" to="/venues" />
    </Switch>
  </BrowserRouter>
);
