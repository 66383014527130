import {
  Box,
  Heading,
  LinkBox,
  LinkOverlay,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';

interface IVenueCardProps {
  id: string;
  name: string;
  city?: string;
  state?: string;
  coverImage?: string;
  isLoading?: boolean;
  style?: React.CSSProperties;
}

export const VenueCard: React.FC<IVenueCardProps> = ({
  id,
  name,
  state,
  city,
  coverImage,
  style,
  isLoading,
}) => {
  if (isLoading) {
    return (
      <Box
        flex="1"
        height="245px"
        bg="gray.700"
        borderRadius="4"
        display="flex"
        style={style}
      >
        <VStack
          flex="1"
          padding="4"
          spacing="0"
          justifyContent="flex-end"
          alignItems="flex-start"
          bg="linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.7) 33%, transparent 60%)"
        >
          <Skeleton mb="1">
            <Heading size="md">Loading Placeholder...</Heading>
          </Skeleton>
          <Skeleton>
            <Text size="s">Description Goes Here</Text>
          </Skeleton>
        </VStack>
      </Box>
    );
  }

  return (
    <LinkBox
      height="245px"
      borderRadius="4"
      flex="1"
      display="flex"
      style={style}
    >
      <VStack
        flex="1"
        padding="4"
        spacing="0"
        justifyContent="flex-end"
        alignItems="flex-start"
        bg="linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.7) 33%, transparent 60%)"
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        zIndex="9"
      >
        <Heading size="md">
          <LinkOverlay href={`/venues/${id}`}>{name}</LinkOverlay>
        </Heading>
        <Text size="s">
          {city}, {state}
        </Text>
      </VStack>
      <Box
        position="absolute"
        bg={coverImage ? `url(${coverImage})` : 'gray.700'}
        bgPos="center center"
        bgSize="cover"
        top="0"
        left="0"
        right="0"
        bottom="0"
      />
    </LinkBox>
  );
};
