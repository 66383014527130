import { Text, VStack } from '@chakra-ui/layout';
import * as React from 'react';

export const Footer: React.FC = () => {
  const year = new Date().getFullYear();

  return (
    <VStack as="footer" spacing="4" padding="8">
      <Text>Copyright &copy; {year} FanFlex LLC. All Rights Reserved.</Text>
    </VStack>
  );
};
