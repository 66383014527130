import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import { useFormik } from 'formik';
import {
  useCollectionDataOnce,
  useDocumentData,
} from 'react-firebase-hooks/firestore';
import { Timestamp, DocumentReference } from '@firebase/firestore-types';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  OrderedList,
  ListItem,
  Select,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { dateTimeFormatterInZone } from '../../lib/dateFormatter';
import { Venue } from '../../types/Venue';
import { Slot } from '../../types/Slot';
import { Artist } from '../../types/Artist';

interface RequestToBookFormProps {
  venueRef: DocumentReference<Venue>;
  artistRef: DocumentReference<Artist>;
  uid: string;
}

const slotValue = (slot: Slot) => slot.when.toDate().toISOString();

export const RequestToBookForm: React.FC<RequestToBookFormProps> = ({
  venueRef,
  artistRef,
  uid,
}) => {
  const [nowish, setNowish] = useState<Timestamp>();
  useEffect(() => setNowish(firebase.firestore.Timestamp.now()), []);

  const [venue] = useDocumentData<Venue>(venueRef);
  const [upcomingSlots, loadingSlots] = useCollectionDataOnce<Slot>(
    nowish
      ? venueRef.collection('slots').where('when', '>', nowish).orderBy('when')
      : undefined,
  );

  const [showThanks, setShowThanks] = useState(false);
  const closeThanks = () => setShowThanks(false);
  const cancelRef = React.useRef(null);

  const formik = useFormik({
    initialValues: {
      message: '',
      slot: '',
    },
    onSubmit: async (values, { resetForm }) => {
      await firebase
        .firestore()
        .collection('requestToBook')
        .add({
          ...values,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          artistRef,
          uid,
          venueRef,
        });
      resetForm();
      setShowThanks(true);
    },
  });

  useEffect(() => {
    if (!loadingSlots && upcomingSlots?.length) {
      formik.setFieldValue('slot', slotValue(upcomingSlots[0]));
    }
  }, [loadingSlots]);

  if (loadingSlots) {
    return null;
  }

  return (
    <>
      <VStack spacing={6} width="100%">
        {upcomingSlots?.length && (
          <FormControl
            id="slot"
            name="slot"
            isInvalid={!!formik.touched.slot && !!formik.errors.slot}
          >
            <FormLabel>Desired Booking Date</FormLabel>
            <Select {...formik.getFieldProps('slot')}>
              {upcomingSlots?.map((slot, id) => (
                <option key={`slot-${id}`} value={slotValue(slot)}>
                  {dateTimeFormatterInZone(venue?.location.timeZone).format(
                    slot.when.toDate(),
                  )}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{formik.errors.slot}</FormErrorMessage>
          </FormControl>
        )}

        <FormControl
          id="message"
          name="message"
          isInvalid={!!formik.touched.message && !!formik.errors.message}
        >
          <FormLabel>Message</FormLabel>
          <Textarea type="text" {...formik.getFieldProps('message')} />
          <FormErrorMessage>{formik.errors.message}</FormErrorMessage>
        </FormControl>

        <Button
          onClick={formik.submitForm}
          isLoading={formik.isSubmitting}
          isDisabled={!formik.isValid}
          size="sm"
          type="submit"
        >
          Submit Request
        </Button>
      </VStack>
      <AlertDialog
        isOpen={showThanks}
        onClose={closeThanks}
        leastDestructiveRef={cancelRef}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>
              Request to book has been received!
            </AlertDialogHeader>
            <AlertDialogBody>
              <Text>
                Please review these few key points:
                <OrderedList spacing={2} mt={2} mb={4}>
                  <ListItem>
                    Could take up to 3 business days to get paid
                  </ListItem>
                  <ListItem>
                    You will contact the venue to confirm tech specs
                  </ListItem>
                  <ListItem>
                    Understand what hardware you need to bring with you
                  </ListItem>
                </OrderedList>
                We'll be in touch soon!
              </Text>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={closeThanks}>
                Close
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
