export const dateFormatter = new Intl.DateTimeFormat('en-US');
export const dateTimeFormatterInZone = (
  timeZone: string | undefined,
): Intl.DateTimeFormat =>
  new Intl.DateTimeFormat('en-US', {
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    month: 'numeric',
    second: 'numeric',
    timeZone,
    timeZoneName: 'short',
    year: 'numeric',
  });
