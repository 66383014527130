import firebase from 'firebase/app';
import React, { useEffect, useState } from 'react';
import {
  InstantSearch,
  connectRefinementList,
  Configure,
} from 'react-instantsearch-dom';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import { Flex, useBreakpointValue, useDisclosure } from '@chakra-ui/react';
import { useCollection } from 'react-firebase-hooks/firestore';
import GoogleMaps from 'google-map-react';

import { Page } from '../../components/Page';
import { typesenseConfig, googleMaps } from '../../config';
import { VenueInfiniteHits } from '../../components/VenueInfiniteHits';
import { Venue } from '../../types/Venue';
import { VenueCard } from '../../components/VenueCard';

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    ...typesenseConfig,
    cacheSearchResultsForSeconds: 2 * 60,
  },
  additionalSearchParameters: {
    queryBy: 'name,city',
    numTypos: 0,
  },
  geoLocationField: 'coords',
});
const searchClient = typesenseInstantsearchAdapter.searchClient;

const VirtualRefinementList = connectRefinementList(() => null);

export const VenuesScreen: React.FC = () => {
  const mapDisplay = useBreakpointValue({ base: 'none', xl: 'block' });
  const [venues, setVenues] = useState<Venue[]>([]);

  const venuesRef = firebase.firestore().collection('venues').limit(10000);
  const [rawVenues] = useCollection<Venue>(venuesRef);

  useEffect(() => {
    if (!rawVenues?.empty) {
      const nextVenues: any = rawVenues?.docs
        ?.map((node) => {
          const data = node?.data() as any;
          const shouldShowOnMap =
            !data?.publiclyHidden && !!data?.location?.coords;

          if (shouldShowOnMap) {
            return { id: node?.id, ...data };
          }
          return;
        })
        ?.filter((node) => !!node);

      setVenues(nextVenues || []);
    }
  }, [rawVenues]);

  return (
    <Page title="Venues" hideFooter lockScroll fixedHeader>
      <InstantSearch indexName="venues" searchClient={searchClient}>
        <Configure hitsPerPage={20} />
        <VirtualRefinementList
          attribute="publiclyHidden"
          defaultRefinement={['false']}
        />
        <Flex h="92vh" w="100vw" mt="8vh">
          <VenueInfiniteHits />
          <div
            style={{
              flex: 1,
              display: mapDisplay,
            }}
          >
            <div style={{ height: '92vh', width: '100%' }}>
              <GoogleMaps
                bootstrapURLKeys={{ key: googleMaps }}
                defaultCenter={{ lat: 35, lng: -97 }}
                defaultZoom={4.5}
              >
                {venues?.map((venue) => (
                  <Marker
                    key={venue?.id}
                    venue={venue}
                    lat={venue?.location?.coords?.latitude}
                    lng={venue?.location?.coords?.longitude}
                  />
                ))}
              </GoogleMaps>
            </div>
          </div>
        </Flex>
      </InstantSearch>
    </Page>
  );
};

const Marker: React.FC<any> = ({ venue }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id, name, location, carouselImages } = venue;

  return (
    <div onMouseEnter={onOpen} onMouseLeave={onClose}>
      <div
        style={{
          width: 20,
          height: 20,
          borderRadius: 30,
          background: '#EA385D',
          border: '3px solid #fff',
          boxShadow: '0 0 15px rgba(0,0,0,0.25)',
        }}
      />
      {isOpen && (
        <VenueCard
          id={id}
          name={name}
          city={location?.city}
          state={location?.state}
          coverImage={carouselImages?.[0]}
          style={{
            width: 200,
            height: 150,
            zIndex: 9999999,
          }}
        />
      )}
    </div>
  );
};
