import { ChevronDownIcon } from '@chakra-ui/icons';
import { Image } from '@chakra-ui/image';
import { HStack } from '@chakra-ui/layout';
import {
  Button,
  VStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import * as React from 'react';
import { Link as RRLink } from 'react-router-dom';

import FF_LOGO from '../../assets/ff-logo-light.svg';
import { useFanflexUser } from '../../hooks/useFanflexUser';
import { artistURLSnapshot } from '../../lib/artist';

interface IHeaderProps {
  fixedHeader?: boolean;
  hideLoginButton?: boolean;
}

export const Header: React.FC<IHeaderProps> = ({
  fixedHeader = false,
  hideLoginButton = false,
}) => {
  const { user, isAdmin, logoutUser, managedArtist } = useFanflexUser();

  const fixedHeaderStyles: any = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 9999,
  };

  return (
    <HStack
      padding="6"
      justifyContent="center"
      width="100%"
      style={fixedHeader ? fixedHeaderStyles : undefined}
      bgColor="gray.900"
      height="8vh"
    >
      <HStack flex="1">
        <Button to="/venues" as={RRLink} size="sm" variant="ghost">
          Browse Venues
        </Button>
      </HStack>
      <VStack flex="1">
        <a target="_blank" href="https://fanflex.com">
          <Image src={FF_LOGO} alt="FanFlex Logo" width="110px" height="40px" />
        </a>
      </VStack>
      <HStack flex="1" justifyContent="flex-end">
        {isAdmin && (
          <Menu>
            <MenuButton
              size="sm"
              variant="ghost"
              as={Button}
              rightIcon={<ChevronDownIcon />}
            >
              Admin Links
            </MenuButton>
            <MenuList>
              <MenuItem to="/admin/artists" as={RRLink}>
                Artists
              </MenuItem>
              <MenuItem to="/admin/venues" as={RRLink}>
                Venues
              </MenuItem>
            </MenuList>
          </Menu>
        )}
        {user ? (
          managedArtist ? (
            <Menu>
              <MenuButton
                size="sm"
                variant="ghost"
                as={Button}
                rightIcon={<ChevronDownIcon />}
              >
                {managedArtist.get('name')}
              </MenuButton>
              <MenuList>
                <MenuItem to={artistURLSnapshot(managedArtist)} as={RRLink}>
                  Profile
                </MenuItem>
                <MenuItem onClick={logoutUser}>Log out</MenuItem>
              </MenuList>
            </Menu>
          ) : (
            <Button size="sm" onClick={logoutUser}>
              Log out
            </Button>
          )
        ) : hideLoginButton ? null : (
          <Button to="/login" as={RRLink} size="sm">
            Log in
          </Button>
        )}
      </HStack>
    </HStack>
  );
};
